import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import './styles/Options.scss';

/**
 * Options component
 * Implements on screen options menue etc.
 * @author: Sam Anderson <`sam@joipolloi.com`>
 * @package: TheSpace-BITB
 */
const Options = ({ active, clickHandler }) => {
    const handleClick = useCallback(() => {
        clickHandler(!active);
    }, [active, clickHandler]);

    return (
        <div className={`Options ${active ? 'Options--active' : ''}`}>
            <button type="button" className="Options__Icon" onClick={handleClick}>
                <span className="vh">Click for options</span>
            </button>
        </div>
    );
};

Options.propTypes = {
    active: PropTypes.bool,
    clickHandler: PropTypes.func,
};

Options.defaultProps = {
    active: false,
    clickHandler: null,
};

export default Options;
