import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'rxjs/operators';
import { animateScroll as scroll } from 'react-scroll';
import { eventService } from '../../rxjs/event';
import { EVENTS, AUTO_SCROLL_DURATION } from '../../cfg/constants';
import { showCurtain } from '../../store/player/actions';
import {
    selectPaused,
    selectCurtainActive,
    selectCurtainContents,
} from '../../store/player/selectors';

export const AutoScroll = ({ children }) => {
    const dispatch = useDispatch();
    const paused = useSelector(selectPaused);
    const curtainActive = useSelector(selectCurtainActive);
    const curtainContents = useSelector(selectCurtainContents);

    useEffect(() => {
        const subscription = eventService
            .getEvent()
            .pipe(filter((event) => event.type === EVENTS.SCROLL))
            .subscribe((event) => {
                const { detail } = event;
                if (curtainActive && !curtainContents) {
                    setTimeout(() => {
                        scroll.scrollToBottom({
                            duration: 0,
                            smooth: false,
                            containerId: 'scroll-container',
                        });
                        dispatch(showCurtain(false));
                    }, 500);
                } else if (!paused) {
                    scroll.scrollToBottom({
                        duration:
                            (detail && detail.duration) || AUTO_SCROLL_DURATION,
                        smooth: 'easeOutQuint',
                        delay: 100,
                        containerId: 'scroll-container',
                    });
                }
            });
        return () => {
            subscription.unsubscribe();
        };
    }, [paused, curtainActive, curtainContents, dispatch]);

    return (
        <div id="scroll-container" className="ChatBot__Scroll">
            {children}
        </div>
    );
};

export default AutoScroll;
