import { combineReducers } from 'redux';
import sessionReducer from './session/reducer';
import playerReducer from './player/reducer';

export default function createReducer() {
    const rootReducer = combineReducers({
        session: sessionReducer,
        player: playerReducer,
    });

    return rootReducer;
}
