const CLOUD_URL_DEV =
    'https://bitb-story-assets.ams3.cdn.digitaloceanspaces.com';
const CLOUD_URL_PROD =
    'https://bitb-production-assets.ams3.cdn.digitaloceanspaces.com';
export const CLOUD_URL =
    process.env.REACT_APP_STAGE === 'production'
        ? CLOUD_URL_PROD
        : CLOUD_URL_DEV;
export const STORAGE_KEY = 'bitbv3';

export const AUTO_SCROLL_DURATION = 1000;
export const MIN_TEXT_DUR_MS = 1000;
export const CURTAIN_DURATION = 2000;
export const SYSTEM_MSG_DELAY_MS = 2000;
export const AUDIO_FADE_MS = 2000;

export const NODE = {
    AUTO: 'Auto',
    LINEAR: 'Linear',
    BRANCH: 'Decision',
    IMAGE: 'Image',
    UNIT_END: 'Unit end',
    CHAPTER_START: 'Chapter Start',
    STORY_END: 'Story ending',
    MARKER: 'Story marker',
    VIDEO: 'Video',
    BG_AUDIO: 'Background audio',
    BG_LOAD: 'Background Load',
    BG_CHANGE: 'Background Change',
    DAY_CHANGE: 'Days',
    PERCENT_CHANGE: 'Percent',
    SYSTEM: 'System Notification',
    INLINE_IMAGE: 'Inline Image',
    INLINE_VIDEO: 'Inline Video',
    AUDIO: 'Audio',
    AUDIO_EFFECT: 'Audio Effect',
    POSITION: 'Chat Position',
    SOUND_EFFECT: 'Sound Effect',
};

export const CONTENT = {
    DIALOG: 'dialogue',
    USER_CHOICE: 'user_choice',
    IMAGE: 'image',
    VIDEO: 'video',
    INLINE_IMAGE: 'inline/image',
    INLINE_VIDEO: 'inline/video',
    UNIT_SUMMARY: 'Unit Summary',
    CHAPTER_START: 'Chapter Start',
    CHAPTER_END: 'Chapter End',
    SYSTEM_NOTIFICATION: 'system_notification',
    PERCENT_CHANGE: 'Percent',
};

export const SCHEMA_TYPES = {
    SCH_ARR: 'array',
    SCH_CUSTOM: 'custom',
    SCH_CUSTOM_ARR: 'customArray',
    SCH_TYPE: 'customType',
};

export const EVENTS = {
    AUDIO: {
        PLAY: 'audio/fx/play',
    },
    BG_AUDIO: {
        CHANGE: 'background/audio/change',
        FADE_OUT: 'background/audio/fade-out',
        FADE_IN: 'background/audio/fade-in',
    },
    BG_LOAD: 'background/load',
    BG_LOADED: 'background/loaded',
    BG_CHANGE: 'background/transition',
    SOUND_EFFECT_UNLOCK: 'soundEffect/unlock',
    SOUND_EFFECT: 'soundEffect/play',
    SOUND_EFFECT_END: 'soundEffect/end',
    LIGHTBOX_OPEN: 'lightbox/open',
    LIGHTBOX_CLOSE: 'lightbox/close',
    DAY_CHANGE: 'day/change',
    DAY_CHANGE_END: 'day/end',
    PERCENT_CHANGE: 'percent/change',
    SESSION_NODE: 'nav/node',
    SESSION_UNIT: 'nav/unit',
    CLEAR_STORAGE: 'debug/clear_storage',
    INLINE_VIDEO_END: 'content/video-end',
    CLICK_CONTINUE: 'global/click/continue',
    EVENT_REMOVE: 'global/event/remove',
    UNPAUSE: 'system/unpause',
    PAUSE: 'system/pause',
    SCROLL: 'player/scroll',
};

export const AUDIO = {
    CHANGE: 'audio/track/change',
    PLAY: 'audio/play',
    PAUSE: 'audio/pause',
    EFFECT: 'audio/effect/play',
    EFFECT_PLAYED: 'audio/effect/played',
};

export const CUSTOM_TYPES = {
    AUTO: 'customAuto',
    NODE: 'customNode',
    VOICE: 'customVoice',
    CHOICE: 'customChoice',
    IMAGE: 'customImage',
    DIALOG: 'customDialog',
    VIDEO: 'customVideo',
    BG: 'customBackground',
    BG_CH: 'customBackgroundChange',
    AUDIO: 'customAudio',
};

export const ANIM = {
    none: '',
    random: 'random-drift',
    leftRight: 'left-right-drift',
};

export const SESSION = {
    INIT_UNIT: 'initUnit',
    DAY: 'day',
    UNIT_PATH: 'unitPath',
    UNIT_HISTORY: 'unitHistory',
    MARKERS: 'seenMarkers',
    BG: 'backgroundSet',
    BG_AUDIO: 'backgroundAudio',
    SOUND_EFFECT: 'soundEffects',
    CFG: 'cfg',
};

// Store as strings for easy writing to storage
export const READ_SPEED = {
    SLOW: '100',
    AVERAGE: '200',
    FAST: '300',
};

export const INIT = {
    day: 1,
    percent: 0,
    initUnit: 'Chapter 1 Debug',
    unitPath: null,
    unitsToLoad: [
        {
            unit: 'Chapter 1 Debug',
            chapter: '1',
        },
    ],
    chapters: ['1'],
    nodeHistory: [],
    seenMarkers: [],
    cfg: {
        audioMute: false,
        subtitles: false,
        readSpeed: READ_SPEED.AVERAGE,
        chatPosition: 'right',
    },
    backgroundSet: {
        layerSet: null,
        layers: [],
    },
    backgroundAudio: false,
};
