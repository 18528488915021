export const SET_NODES = 'SET_NODES';
export const SET_MARKERS = 'SET_MARKERS';
export const SHOW_LIGHTBOX = 'SHOW_LIGHTBOX';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const PLAYER_ERROR = 'PLAYER_ERROR';
export const PLAYER_PAUSE = 'PLAYER_PAUSE';
export const PLAYER_UNPAUSE = 'PLAYER_UNPAUSE';
export const SET_CURTAIN_CONTENT = 'SET_CURTAIN_CONTENT';
export const SET_PAUSED_CONTENT = 'SET_PAUSED_CONTENT';
export const SHOW_CURTAIN = 'SHOW_CURTAIN';
export const SET_INIT = 'SET_INIT';
export const SET_FULLSCREEN = 'SET_FULLSCREEN';
export const SET_VIDEO_FULLSCREEN = 'SET_VIDEO_FULLSCREEN';
