import React from 'react';
import { useSelector } from 'react-redux';
import { selectSpinner } from '../../store/player/selectors';
import './styles/Ellipse.scss';

const Spinner = ({ show }) => {
    const spinner = useSelector(selectSpinner);
    if (!show && !spinner) {
        return null;
    }
    return (
        <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

export default Spinner;
