import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { CLOUD_URL, CONTENT, EVENTS } from "../../cfg/constants";
import { ConfigProps, VoiceProps } from "../../cfg/customProps";
import { eventService } from "../../rxjs/event";
import { showLightbox } from "../../store/player/actions";
import { loadNode } from "../../store/session/actions";
import { Video } from "../Interface";
import "./styles/Card.scss";
import { customMarkup } from "./utils";

/**
 * Inline content component
 *
 * @author: Sam Anderson <`sam@joipolloi.com`>
 * @package: TheSpace-BITB
 */
class InlineContentCard extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        progress: PropTypes.string,
        media: PropTypes.shape({
            src: PropTypes.string.isRequired,
            text: PropTypes.string,
            voice: VoiceProps,
            autoOpen: PropTypes.bool,
        }),
        type: PropTypes.string.isRequired,
        interactive: PropTypes.bool,
        thumbnail: PropTypes.string.isRequired,
        cfg: ConfigProps.isRequired,
        sound: PropTypes.func.isRequired,
    };

    static defaultProps = {
        title: "",
        media: {},
        interactive: false,
        progress: null,
    };

    state = {
        active: false,
        loaded: false,
    };

    componentDidMount() {
        const { interactive, media } = this.props;

        if (interactive && media.autoOpen) {
            this.openLightbox();
        }

        this.setState({
            active: !interactive,
        });
    }

    openLightbox = () => {
        const {
            media: { src },
            showLightbox,
        } = this.props;
        showLightbox({
            src,
            type: "image",
            options: {
                progressOnComplete: false,
            },
        });
    };

    clickHandler = () => {
        const { progress, interactive, loadNode } = this.props;
        if (interactive && progress) {
            this.setState(
                {
                    active: true,
                },
                () => loadNode(progress)
            );
        }
    };

    onEnd = () => {
        this.clickHandler();
    };

    handleLoaded() {
        const { interactive } = this.props;
        this.setState({
            loaded: true,
        });
        if (interactive) {
            setTimeout(() => {
                eventService.sendEvent(EVENTS.SCROLL);
            }, 300);
        }
    }

    handleSound() {
        const { interactive, sound } = this.props;
        const { loaded } = this.state;
        if (interactive && !loaded) {
            sound();
        }
    }

    /* eslint-disable react/no-danger */
    render() {
        const {
            cfg,
            title,
            type,
            media: { src, text, voice },
            thumbnail,
            interactive,
        } = this.props;
        const { active } = this.state;
        const isVideo = type === CONTENT.INLINE_VIDEO;

        const classes = classNames(
            "Card",
            "Card--required",
            { "Card--image": !isVideo },
            { "Card--noText": !text },
            { "Card--video": isVideo },
            { activated: active }
        );

        return (
            <>
                <article className={classes}>
                    <div
                        className="Card__Wrapper Card__Wrapper--loaded"
                        onLoad={this.handleSound.bind(this)}
                    >
                        {voice && (
                            <img
                                className="Card__Avatar"
                                src={`${CLOUD_URL}/img/avatar/${voice.defaultImg}`}
                                alt={title}
                                onLoad={this.handleLoaded.bind(this)}
                            />
                        )}
                        <div className="Card__Content">
                            {voice && <h1 className="vh">{voice.name}</h1>}
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: customMarkup(text),
                                }}
                            />
                        </div>
                        {isVideo && (
                            <div className="Card__Video hide-track">
                                <Video
                                    src={src}
                                    endHandler={this.onEnd}
                                    subtitles={cfg.subtitles}
                                    autoPlay={false}
                                    interactive={interactive}
                                />
                            </div>
                        )}
                        {!isVideo && (
                            <div className="Card__Content--inline-container">
                                {/*  eslint-disable-next-line max-len */}
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                                <div className="Card__Content--inline-image-wrapper">
                                    <img
                                        className="Card__Content--inline override-global-click"
                                        src={thumbnail}
                                        alt={title}
                                        onClick={this.clickHandler}
                                        onKeyDown={this.clickHandler}
                                        onLoad={this.handleLoaded.bind(this)}
                                    />
                                    <div
                                        className="Card__Expander"
                                        onClick={this.openLightbox}
                                        onKeyDown={this.openLightbox}
                                        role="button"
                                        tabIndex={-1}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </article>
            </>
        );
    }
    /* eslint-enable react/no-danger */
}

const mapDispatchToProps = (dispatch) => ({
    showLightbox: (payload) => {
        dispatch(showLightbox(payload));
    },
    loadNode: (payload) => {
        dispatch(loadNode(payload));
    },
});
export default connect(null, mapDispatchToProps)(InlineContentCard);
