import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLOUD_URL, CONTENT, EVENTS } from '../../cfg/constants';
import { stripExtension } from '../../lib';
import { eventService } from '../../rxjs/event';
import {
    selectStartNode,
    selectCurrentChapter,
} from '../../store/player/selectors';
import { loadInteraction, loadNode } from '../../store/session/actions';
import {
    selectCfg,
    selectInitUnit,
    selectNodeHistory,
} from '../../store/session/selectors';
import ChoiceCard from './ChoiceCard';
import ContentCard from './ContentCard';
import InlineContentCard from './InlineContentCard';
import './styles/ChatBot.scss';
import SummaryCard from './SummaryCard';
import SystemCard from './SystemCard';
import TextCard from './TextCard';
import ChapterEndCard from './ChapterEndCard';

export const ChatBot = (props) => {
    const { unit, show } = props;
    const dispatch = useDispatch();
    const initUnit = useSelector(selectInitUnit);
    const nodeHistory = useSelector((state) => selectNodeHistory(state, unit));
    const startNode = useSelector(selectStartNode);
    const currentChapter = useSelector(selectCurrentChapter);
    const cfg = useSelector(selectCfg);

    useEffect(() => {
        if (initUnit === unit && startNode) {
            if (!nodeHistory.length) {
                dispatch(loadNode(startNode));
            } else {
                dispatch(loadInteraction(nodeHistory.pop()));
            }
        }
    }, [initUnit, unit, dispatch]);

    const playSound = useCallback(() => {
        setTimeout(
            () =>
                eventService.sendEvent(EVENTS.AUDIO.PLAY, {
                    name: 'chatSound',
                }),
            800
        );
    }, []);
    // console.log("Chatbot render", unit);

    const renderSummary = (content) => (
        <SummaryCard {...content} card data={content} />
    );

    const renderContent = (content, currentHead = false) => {
        switch (content.type) {
            case CONTENT.DIALOG:
                return (
                    <TextCard
                        id={content.id}
                        key={content.key}
                        historyId={content.historyId}
                        title={content.voice.name}
                        text={content.text}
                        voice={content.voice}
                        isHead={currentHead}
                        sound={playSound}
                    />
                );
            case CONTENT.USER_CHOICE:
                return (
                    <ChoiceCard
                        key={content.key}
                        historyId={content.historyId}
                        bookmark={content.bookmark}
                        choices={content.choices}
                        initChosen={!!content.choice}
                        initChosenId={content.choice}
                        isHead={currentHead}
                    />
                );
            case CONTENT.IMAGE: {
                let { autoProgress, src, autoOpen } = content.media;

                let imageArray = [];
                // Deal with multiple images block
                // Only pull the first image as thumbnail
                if (Object.keys(content.media)[0] === '0') {
                    autoProgress = content.media[0].autoProgress;
                    src = content.media[0].src;
                    autoOpen = content.media[0].autoOpen;
                    imageArray = content.media;
                }
                const thumbnail = stripExtension(src, '.jpg');
                return (
                    <ContentCard
                        key={content.key}
                        historyId={content.historyId}
                        id={content.id}
                        progress={content.progress}
                        thumbnail={`${CLOUD_URL}/img/thumbnail/${thumbnail}`}
                        src={src}
                        autoOpen={autoOpen}
                        autoProgress={autoProgress}
                        type={content.type}
                        cfg={cfg}
                        interactive={currentHead}
                        imageArray={imageArray}
                    />
                );
            }
            case CONTENT.VIDEO: {
                const { autoProgress, src, autoOpen } = content.media;
                const thumbnail = stripExtension(src, '.jpg');
                return (
                    <ContentCard
                        key={content.key}
                        historyId={content.historyId}
                        id={content.id}
                        progress={content.progress}
                        thumbnail={`${CLOUD_URL}/img/thumbnail/${thumbnail}`}
                        src={src}
                        autoOpen={autoOpen}
                        autoProgress={autoProgress}
                        type={content.type}
                        cfg={cfg}
                        current={currentHead}
                        interactive={currentHead}
                    />
                );
            }
            case CONTENT.INLINE_VIDEO:
            case CONTENT.INLINE_IMAGE: {
                const { src } = content.media;

                const thumbnail = stripExtension(src, '.jpg');
                return (
                    <InlineContentCard
                        key={content.key}
                        historyId={content.historyId}
                        interactive={currentHead}
                        id={content.id}
                        progress={content.progress}
                        thumbnail={`${CLOUD_URL}/img/thumbnail/${thumbnail}`}
                        type={content.type}
                        media={content.media}
                        cfg={cfg}
                        sound={playSound}
                    />
                );
            }
            case CONTENT.SYSTEM_NOTIFICATION:
                return (
                    <SystemCard
                        key={content.key}
                        className={content.classes}
                        message={content.message}
                        isHead={currentHead}
                    />
                );
            case CONTENT.UNIT_SUMMARY:
                return <SummaryCard {...content} card data={content} />;
            case CONTENT.CHAPTER_END:
                if (currentChapter !== content.chapter) {
                    return null;
                }
                return (
                    <ChapterEndCard
                        {...content}
                        card
                        data={content}
                        isHead={currentHead}
                        sound={playSound}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            {nodeHistory.map((item, idx) => {
                const isLast = idx === nodeHistory.length - 1;
                return show ? renderContent(item, isLast) : renderSummary(item);
            })}
        </>
    );
};

export default ChatBot;
