import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import screenfull from 'screenfull';
import { READ_SPEED } from '../../cfg/constants';
import { setCfg } from '../../store/session/actions';
import { isMobile } from '../../utils';
import './styles/Menu.scss';
import { setFullscreen, setVideoFullscreen } from '../../store/player/actions';
import { selectFullscreen } from '../../store/player/selectors';
import classNames from 'classnames';

class Menu extends PureComponent {
    static propTypes = {
        active: PropTypes.bool,
        cfg: PropTypes.shape({
            audioMute: PropTypes.bool.isRequired,
            readSpeed: PropTypes.string.isRequired,
            subtitles: PropTypes.bool.isRequired,
        }).isRequired,
        updateCfg: PropTypes.func.isRequired,
        clickHandler: PropTypes.func.isRequired,
    };

    static defaultProps = {
        active: true,
    };

    constructor(props) {
        super(props);
        this.root = document.getElementById('root');
        this.fullscreenChange = this.fullscreenChange.bind(this);
        if (screenfull.isEnabled) {
            screenfull.on('change', this.fullscreenChange);
        }
        this.state = {
            isMobile: isMobile(),
        };
    }

    componentDidMount() {
        const { updateFullscreen } = this.props;
        updateFullscreen(screenfull.isFullscreen);
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = (e) => {
        const { clickHandler } = this.props;
        if (!this.node) {
            return;
        }

        if (this.node.contains(e.target)) {
            return;
        }
        clickHandler(false);
    };

    changeHandler = (e) => {
        const { updateCfg } = this.props;
        const {
            target: { name, value },
        } = e;

        updateCfg({ [name]: value });
    };

    updateHandler = (data) => {
        const { updateCfg } = this.props;
        updateCfg(data);
    };

    fullscreenChange = (e) => {
        const { updateFullscreen, updateVideoFullscreen } = this.props;
        if (screenfull.element && screenfull.element !== this.root) {
            return;
        }
        updateFullscreen(screenfull.isFullscreen);
        if (!screenfull.isFullscreen) {
            updateVideoFullscreen(false);
        }
    };

    toggleFullScreen = () => {
        screenfull.toggle(this.root);
    };

    render() {
        const { active, cfg, clickHandler, fullscreen } = this.props;
        const { isMobile } = this.state;
        const subtitleLabel = `${
            cfg.subtitles ? 'Disable ' : 'Enable '
        } subtitles `;
        const muteLabel = `${cfg.audioMute ? 'Enable ' : 'Mute '}`;
        const fullScreenLabel = `${
            fullscreen ? 'Exit Fullscreen' : 'Show Fullscreen'
        } `;

        return (
            <div className={`Menu ${active ? 'Menu--active' : ''}`}>
                <section className='Menu__Container'>
                    <div
                        className='Menu__Content'
                        ref={(node) => (this.node = node)}
                    >
                        <h1 className='Menu__Title'>Settings</h1>
                        <h2 className='Menu__Title'>Subtitles</h2>
                        <div className='Menu__Row'>
                            <button
                                type='button'
                                className={classNames('Menu__Button', {
                                    active: cfg.subtitles,
                                })}
                                onClick={() =>
                                    this.updateHandler({
                                        subtitles: !cfg.subtitles,
                                    })
                                }
                            >
                                {subtitleLabel}
                            </button>
                        </div>
                        <h2 className='Menu__Title'>
                            Music &amp; Sound Effects
                        </h2>
                        <div className='Menu__Row'>
                            <button
                                type='button'
                                className={classNames('Menu__Button', {
                                    active: cfg.audioMute,
                                })}
                                onClick={() =>
                                    this.updateHandler({
                                        audioMute: !cfg.audioMute,
                                    })
                                }
                            >
                                {muteLabel}
                            </button>
                        </div>
                        <h2 className='Menu__Title'>Read speed</h2>
                        <div className='Menu__Row'>
                            <label className='Menu__Radio' htmlFor='slow'>
                                <input
                                    type='radio'
                                    value={READ_SPEED.SLOW}
                                    name='readSpeed'
                                    id='slow'
                                    onChange={this.changeHandler}
                                    checked={cfg.readSpeed === READ_SPEED.SLOW}
                                />
                                Slow
                            </label>
                            <label className='Menu__Radio' htmlFor='average'>
                                <input
                                    type='radio'
                                    value={READ_SPEED.AVERAGE}
                                    name='readSpeed'
                                    id='average'
                                    onChange={this.changeHandler}
                                    checked={
                                        cfg.readSpeed === READ_SPEED.AVERAGE
                                    }
                                />
                                Average
                            </label>
                            <label className='Menu__Radio' htmlFor='fast'>
                                <input
                                    type='radio'
                                    value={READ_SPEED.FAST}
                                    name='readSpeed'
                                    id='fast'
                                    onChange={this.changeHandler}
                                    checked={cfg.readSpeed === READ_SPEED.FAST}
                                />
                                Fast
                            </label>
                        </div>
                        {!isMobile && (
                            <>
                                <h2 className='Menu__Title'>FullScreen</h2>
                                <div className='Menu__Row'>
                                    <button
                                        type='button'
                                        className={classNames('Menu__Button', {
                                            active: fullscreen,
                                        })}
                                        onClick={() => this.toggleFullScreen()}
                                    >
                                        {fullScreenLabel}
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                    <button
                        type='button'
                        className='Menu__Close'
                        onClick={() => clickHandler(false)}
                    >
                        Close
                    </button>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    fullscreen: selectFullscreen(state),
});

const mapDispatchToProps = (dispatch) => ({
    updateCfg: (payload) => {
        dispatch(setCfg(payload));
    },
    updateFullscreen: (payload) => {
        dispatch(setFullscreen(payload));
    },
    updateVideoFullscreen: (payload) => {
        dispatch(setVideoFullscreen(payload));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
