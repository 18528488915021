import React from 'react';
import { useSelector } from 'react-redux';
import './styles/Percent.scss';
import { selectPercent } from '../../store/session/selectors';
/**
 * UI component to display current 'Day' of experience
 * @author: Sam Anderson <`sam@joipolloi.com`>
 * @package: TheSpace-BITB
 */

const Percent = () => {
    const percent = useSelector(selectPercent);
    const percentStyle = {
        transform: `translateY(${100 - percent}%)`,
    };
    return (
        <div className='Percent'>
            <svg
                width='154px'
                height='190px'
                viewBox='0 0 154 190'
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                xlinkHref='http://www.w3.org/1999/xlink'
            >
                <title>progress</title>
                <defs>
                    <path
                        d='M155,190 L1,190 L1,43 C1,19.2517558 20.2517558,4.36248169e-15 44,0 L112,0 C135.748244,-4.36248169e-15 155,19.2517558 155,43 L155,190 Z'
                        id='path-1'
                    ></path>
                </defs>
                <g
                    id='progress'
                    stroke='none'
                    strokeWidth='1'
                    fill='none'
                    fillRule='evenodd'
                >
                    <g
                        id='percent-bar'
                        transform='translate(-1.000000, 0.000000)'
                    >
                        <mask id='mask-2' fill='white'>
                            <use xlinkHref='#path-1'></use>
                        </mask>
                        <path
                            stroke='#D28501'
                            strokeWidth='8'
                            d='M112,4 L44,4 C33.2304474,4 23.4804474,8.36522369 16.4228355,15.4228355 C9.36522369,22.4804474 5,32.2304474 5,43 L5,186 L151,186 L151,43 C151,32.2304474 146.634776,22.4804474 139.577164,15.4228355 C132.519553,8.36522369 122.769553,4 112,4 Z'
                        ></path>
                        <rect
                            id='bg'
                            fill='#FFFFFF'
                            mask='url(#mask-2)'
                            x='0'
                            y='0'
                            width='156'
                            height='190'
                        ></rect>
                        <rect
                            id='progress'
                            fill='#CCCCCC'
                            mask='url(#mask-2)'
                            x='1'
                            y='0'
                            width='154'
                            height='190'
                            style={percentStyle}
                        ></rect>
                        <path
                            d='M112,4 L44,4 C33.2304474,4 23.4804474,8.36522369 16.4228355,15.4228355 C9.36522369,22.4804474 5,32.2304474 5,43 L5,186 L151,186 L151,43 C151,32.2304474 146.634776,22.4804474 139.577164,15.4228355 C132.519553,8.36522369 122.769553,4 112,4 Z'
                            id='frame'
                            stroke='#D28501'
                            strokeWidth='8'
                            mask='url(#mask-2)'
                        ></path>
                    </g>
                </g>
            </svg>
            <div className='Percent__Text'>{percent}%</div>
        </div>
    );
};

export default React.memo(Percent);
