import React, { Component } from 'react';

const ChapterContext = React.createContext();

class ChapterProvider extends Component {
  // Context state
  state = {
      show: false,
  };

  // Method to update state
  setShow = show => {
      this.setState(() => ({ show }));
  };

  render() {
      const { children } = this.props;
      const { show } = this.state;
      const { setShow } = this;

      return (
          <ChapterContext.Provider
              value={{
                  show,
                  setShow,
              }}
          >
              {children}
          </ChapterContext.Provider>
      );
  }
}

export default ChapterContext;

export { ChapterProvider };
