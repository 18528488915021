import React, { useCallback, useEffect, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { useSelector } from 'react-redux';
import { EVENTS } from '../../cfg/constants';
import { eventService } from '../../rxjs/event';
import {
    selectLightbox,
    selectStartNode,
    selectFullscreen,
    selectVideoFullscreen,
} from '../../store/player/selectors';
import {
    selectBackgroundAudio,
    selectCfg,
    selectChapters,
} from '../../store/session/selectors';
import AudioController from '../AudioController';
import SoundEffects from '../AudioController/SoundEffects';
import { Menu, Options } from '../Interface';
import Background from '../Interface/Background';
import Curtain from '../Interface/Curtain';
import Days from '../Interface/Days';
import Spinner from '../Interface/Spinner';
import Lightbox from '../Lightbox';
import AutoScroll from './AutoScroll';
import Chapter from './Chapter';
import { ChapterProvider } from './ChapterContext';
import DebugInfo from './DebugInfo';
import classNames from 'classnames';
import './styles/Player.scss';
import Percent from '../Interface/Percent';

export const BitbPlayer = () => {
    const [menu, setMenu] = useState(false);
    const startNode = useSelector(selectStartNode);
    const lightbox = useSelector(selectLightbox);
    const chapters = useSelector(selectChapters);
    const cfg = useSelector(selectCfg);
    const initAudio = useSelector(selectBackgroundAudio);
    const fullscreen = useSelector(selectFullscreen);
    const videoFullscreen = useSelector(selectVideoFullscreen);

    const unlockAudio = useCallback(() => {
        eventService.sendEvent(EVENTS.SOUND_EFFECT_UNLOCK, {
            name: 'chatSound',
        });
        document.body.removeEventListener('click', unlockAudio);
        document.body.removeEventListener('touchstart', unlockAudio);
    });

    useEffect(() => {
        document.body.addEventListener('click', unlockAudio);
        document.body.addEventListener('touchstart', unlockAudio);
        return () => {
            document.body.removeEventListener('click', unlockAudio);
            document.body.removeEventListener('touchstart', unlockAudio);
        };
    });

    const clickHandler = useCallback((show) => {
        setMenu(show);
    }, []);

    if (!startNode) {
        return null;
    }

    const bgImg = 'url("./assets/placehold.png")';
    // console.log('BitbPlayer render');

    const classes = classNames('Player', { fullscreen, videoFullscreen });

    return (
        <div
            className={classes}
            style={{ background: bgImg, backgroundSize: 'cover' }}
        >
            <Background />
            <Days />
            <Percent />
            <Options active={menu} clickHandler={clickHandler} />
            <Div100vh
                as='div'
                className={`ChatBot ChatBot--${cfg.chatPosition}`}
                style={{ height: '92rvh' }}
            >
                <AutoScroll>
                    <div className='ChatBot__Spacer' />
                    {chapters.map((chapter) => (
                        <ChapterProvider key={chapter}>
                            <Chapter chapter={chapter} />
                        </ChapterProvider>
                    ))}
                </AutoScroll>
                <div id='spinner'>
                    <Spinner />
                </div>
            </Div100vh>
            <DebugInfo />
            <Menu active={menu} cfg={cfg} clickHandler={clickHandler} />
            <Curtain />
            <AudioController init={initAudio} mute={cfg.audioMute} />
            <SoundEffects />
            <Lightbox detail={lightbox} cfg={cfg} />
        </div>
    );
};

export default BitbPlayer;
