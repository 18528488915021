/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { customMarkup } from './utils';

import './styles/Card.scss';

/**
 * ChoiceButton component
 *
 * @author: Sam Anderson <`sam@joipolloi.com`>
 * @package: TheSpace-BITB
 */

export default class ChoiceButton extends React.Component {
    static propTypes = {
        chosen: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        idx: PropTypes.number,
        text: PropTypes.string.isRequired,
        leaving: PropTypes.bool,
        paused: PropTypes.bool,
    };

    static defaultProps = {
        chosen: false,
        idx: 1,
        leaving: false,
        paused: false,
    };

    state = { animated: false };

    /**
     * Delay animating choice in by index
     */
    componentDidMount() {
        setTimeout(() => this.setState({ animated: true }), 200);
    }

    shouldComponentUpdate() {
        const { paused } = this.props;
        return !paused;
    }

    render() {
        const { animated } = this.state;
        const {
            chosen, text, leaving, onClick, idx,
        } = this.props;

        const className = classNames(
            'Card__Choice animate-choices',
            { 'Card__Choice--chosen': chosen },
            { 'Card__Choice--display': animated },
            { 'Card__Choice--leave': (leaving && !chosen) },
        );

        /* eslint-disable react/no-danger */
        return (
            <button
                type="button"
                className={className}
                onClick={() => onClick(idx)}
                dangerouslySetInnerHTML={{ __html: customMarkup(text) }}
                disabled={leaving}
            />
        );
        /* eslint-enable react/no-danger */
    }
}
