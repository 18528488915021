import React from 'react';
import { useSelector } from 'react-redux';
import { version } from '../../../package.json';
import { selectCurrentChapter } from '../../store/player/selectors';
import { selectInitUnit } from '../../store/session/selectors';
import './styles/Debug.scss';
/**
 * Dev component to display current env/version
 * @author: Sam Anderson <`sam@joipolloi.com`>
 * @package: TheSpace-BITB
 */
const DebugInfo = () => {
    const currentUnit = useSelector(selectInitUnit);
    const currentChapter = useSelector(selectCurrentChapter);
    if (process.env.NODE_ENV === 'production') {
        return null;
    }

    const debugProps = {
        title: 'The Boy In The Book',
        env:
            process.env.NODE_ENV === 'development'
                ? 'INTERNAL DEV VERSION'
                : 'DEMO',
    };
    return (
        <div className="Debug">
            <span className="Debug__Title">{debugProps.title}</span>
            <ul className="Debug__Info">
                <li className="Debug__Item">{debugProps.env}</li>
                <li className="Debug__Item">{`Version: ${version}`}</li>
                <li className="Debug__Item">{`Chapter: ${currentChapter}`}</li>
                <li className="Debug__Item">{`Unit: ${currentUnit}`}</li>
                {/* <li className="Debug__Item">Press d for debugger</li> */}
            </ul>
        </div>
    );
};

export default DebugInfo;
