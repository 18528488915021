import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { Transition, TransitionGroup } from "react-transition-group";
import { ANIM, CLOUD_URL } from "../../cfg/constants";
import { selectLayers, selectLayerSet } from "../../store/session/selectors";
import "./styles/Background.scss";

const Background = () => {
    const layerSet = useSelector(selectLayerSet);
    const layers = useSelector(selectLayers);

    if (!layerSet) {
        return null;
    }

    const renderLayer = (item, idx) => {
        const layer = item.layer !== "" ? item.layer : "0";
        const anim = ANIM[layer.animation];
        const hasAnimation = anim && typeof anim !== "undefined";

        const classes = classNames(
            "Background__Layer",
            { animated: hasAnimation },
            { first: idx === 0 },
            anim
        );

        const defaultStyle = {
            transition: `opacity 1000ms ease-in-out`,
            opacity: 0,
        };

        const transitionStyles = {
            entering: { opacity: 0 },
            entered: { opacity: 1 },
            exiting: { opacity: 0 },
            exited: { opacity: 0 },
        };

        const path = `${CLOUD_URL}/img/background/${layerSet}/${layer}.png`;

        if (!item.fadeIn) {
            return null;
        }

        return (
            <Transition
                key={`${layerSet}-${layer}`}
                in={item.fadeIn}
                timeout={1000}
            >
                {(state) => (
                    <div
                        className={classes}
                        style={{
                            ...defaultStyle,
                            ...transitionStyles[state],
                        }}
                    >
                        <img src={path} alt="" />
                    </div>
                )}
            </Transition>
        );
    };

    return (
        <>
            <TransitionGroup className="Background __new">
                {layers.map(renderLayer)}
            </TransitionGroup>
        </>
    );
};

export default React.memo(Background);
