import React from 'react';
import PropTypes from 'prop-types';

const Counter = ({ number, className }) => <span className={className}>{number}</span>;

export default Counter;

Counter.propTypes = {
    className: PropTypes.string,
    number: PropTypes.number.isRequired,
};

Counter.defaultProps = {
    className: '',
};
