import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { eventService } from '../../rxjs/event';
import { EVENTS } from '../../cfg/constants';

const SystemCard = ({
    className, message, paused, isHead,
}) => {
    useEffect(() => {
        if (isHead && !paused) {
            eventService.sendEvent(EVENTS.SCROLL);
        }
    }, [paused, isHead]);

    if (paused) {
        return null;
    }

    return (
        <article className={`Card Card--system ${className}`}>
            <p>{message}</p>
        </article>
    );
};

SystemCard.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string.isRequired,
    paused: PropTypes.bool,
    isHead: PropTypes.bool,
};

SystemCard.defaultProps = {
    className: '',
    paused: false,
    isHead: false,
};

export default memo(SystemCard);
