import 'core-js/stable/array/find';
import screenfull from 'screenfull';
import UnitController from './components/UnitController';
import { isMobile } from './utils';
import './styles/App.scss';

const root = document.getElementById('root');

const launch = () => {
    const player = new UnitController(root);
    if (screenfull.isEnabled && !isMobile()) {
        screenfull.request(root);
    }
    player.init();
};

// When the DOM is ready, load content
document.addEventListener('DOMContentLoaded', () => {
    // Initialise Unit Controller
    const buttons = document.getElementsByClassName('game-buttons');
    const end = document.getElementById('end-screen');
    const newGame = document.querySelectorAll('.new-game');
    const playGame = document.querySelectorAll('.play-game');

    const mongoId = localStorage.getItem('bitbv3');

    // Check if we have any previous data

    newGame.forEach((item) => {
        if (!mongoId) {
            item.style.display = 'none';
        }
        item.addEventListener('click', (e) => {
            if (!window.confirm('Do you really want to do this?')) {
                e.preventDefault();
            } else {
                localStorage.clear();
                launch();
            }
        });
    });

    playGame.forEach((item) => {
        if (mongoId) {
            item.innerText = 'Continue Story';
        }
        item.addEventListener('click', (event) => {
            launch();
        });
    });

    for (let i = 0; i < buttons.length; i++) {
        buttons[i].style.display = 'flex';
    }

    window.addEventListener('ENDED', () => {
        end.style.display = 'block';
    });

    //Modals
    const btn = document.querySelectorAll('a.modal-button');
    const modals = document.querySelectorAll('.modal');
    const spans = document.getElementsByClassName('close');

    for (let i = 0; i < btn.length; i++) {
        btn[i].onclick = function (e) {
            for (const index in modals) {
                if (typeof modals[index].style !== 'undefined')
                    modals[index].style.display = 'none';
            }
            e.preventDefault();
            const modal = document.querySelector(e.target.getAttribute('href'));
            modal.style.display = 'block';
        };
    }

    for (let i = 0; i < spans.length; i++) {
        spans[i].onclick = function () {
            for (const index in modals) {
                if (typeof modals[index].style !== 'undefined')
                    modals[index].style.display = 'none';
            }
        };
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
        if (event.target.classList.contains('modal')) {
            for (var index in modals) {
                if (typeof modals[index].style !== 'undefined')
                    modals[index].style.display = 'none';
            }
        }
    };

    // Setup video
    const mainStartScreen = document.getElementById('main-start-screen');
    if (!mainStartScreen) return;
    const video = mainStartScreen.querySelector('video');
    if (!video) return;
    const control = mainStartScreen.querySelector('.control');
    const volume = mainStartScreen.querySelector('.volume');

    if (!isMobile()) {
        video.autoplay = true;
        video.load();
        mainStartScreen.classList.add('playing', 'muted');
        mainStartScreen.classList.remove('paused');
    } else {
        video.load();
        mainStartScreen.classList.add('paused');
        mainStartScreen.classList.remove('playing');
    }

    video.addEventListener('loadeddata', () => {
        if (video.readyState >= 2) {
            mainStartScreen.classList.add('ready');
        }
    });

    control.addEventListener('click', () => {
        if (video.paused) {
            video
                .play()
                .then(() => {
                    // Played successfully
                    mainStartScreen.classList.add('playing');
                    mainStartScreen.classList.remove('paused');
                })
                .catch((error) => {
                    // Playback failed
                    console.error(error);
                });
        } else {
            video.pause();
            mainStartScreen.classList.add('paused');
            mainStartScreen.classList.remove('playing');
        }
    });

    volume.addEventListener('click', () => {
        video.muted = !video.muted;
        mainStartScreen.classList.toggle('muted', video.muted);
    });
});
