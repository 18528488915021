import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCurtainActive,
    selectCurtainContents,
} from '../../store/player/selectors';
import { unitContinue, startChapter } from '../../store/session/actions';
import ChapterContext from '../BitbPlayer/ChapterContext';
import SummaryCard from '../ChatBot/SummaryCard';
import Spinner from './Spinner';
import './styles/Curtain.scss';
import { CONTENT } from '../../cfg/constants';

const Curtain = () => {
    const dispatch = useDispatch();
    const active = useSelector(selectCurtainActive);
    const curtainContents = useSelector(selectCurtainContents);
    const classes = classNames('Curtain', { 'Curtain--active': active });

    const continueUnitSummary = useCallback(() => {
        if (curtainContents.content.type === CONTENT.CHAPTER_START) {
            dispatch(startChapter(curtainContents.node));
        } else {
            dispatch(unitContinue(curtainContents.node));
        }
    }, [curtainContents, dispatch]);

    const button_text =
        curtainContents &&
        curtainContents.content.type === CONTENT.CHAPTER_START
            ? 'Start'
            : 'Continue';

    return (
        <div className={classes}>
            <div className='Curtain__Container'>
                {curtainContents && (
                    <ChapterContext.Provider value={{ show: true }}>
                        <SummaryCard
                            {...curtainContents.content}
                            card={false}
                            display
                            data={curtainContents.content}
                            clickHandler={continueUnitSummary}
                            button_text={button_text}
                        />
                    </ChapterContext.Provider>
                )}
                {!curtainContents && <Spinner show />}
            </div>
        </div>
    );
};

export default Curtain;
