import PropTypes from 'prop-types';

export const ImageProps = PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    auto: PropTypes.bool,
});

export const ChoiceProps = PropTypes.shape({
    text: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    message: PropTypes.string,
});

export const OptionProps = PropTypes.shape({
    progress: PropTypes.string,
    choices: PropTypes.arrayOf(ChoiceProps),
    image: ImageProps.object,
});

export const VoiceProps = PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultImg: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

export const ContentProps = PropTypes.shape({
    text: PropTypes.string.isRequired,
    voice: PropTypes.string.isRequired,
    image: PropTypes.string,
});

export const NodeProps = PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(ContentProps).isRequired,
    options: OptionProps.isRequired,
});

export const HistoryProps = PropTypes.shape({
    key: PropTypes.string,
    lineId: PropTypes.number,
    // nodeId: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
    voice: VoiceProps,
});

// export const MarkerProps = PropTypes.shape({
//     active: PropTypes.bool,
//     id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//     title: PropTypes.string,
// });

export const VideoProps = PropTypes.shape({
    src: PropTypes.string,
    thumbnail: PropTypes.string,
});

export const BookmarkProps = PropTypes.shape({
    unit: PropTypes.string,
    node: PropTypes.string,
});

export const ConfigProps = PropTypes.shape({
    subtitles: PropTypes.bool.isRequired,
});
