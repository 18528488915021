/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { filter } from 'rxjs/operators';
import { AUDIO_FADE_MS, EVENTS, CLOUD_URL } from '../../cfg/constants';
import { eventService } from '../../rxjs/event';
import { isMobile, isAndroid } from '../../utils';
import { adjustVolume } from './utils';

const API_ENDPOINT = `${CLOUD_URL}/background-audio`;

/**
 * UI component to control background and sound effects
 * @author: Sam Anderson <`sam@joipolloi.com`>
 * @package: TheSpace-BITB
 */
export default class AudioController extends PureComponent {
    static propTypes = {
        init: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
            .isRequired,
        mute: PropTypes.bool.isRequired,
    };

    state = {
        activePlayer: null,
        waitingPlayer: null,
        faded: false,
        unlocked: false,
        fx: {
            chatSound: new Audio('/audio/chat-sound.mp3'),
        },
    };

    constructor(props) {
        super(props);
        this.listener = null;
    }

    componentDidMount() {
        const { init, mute } = this.props;

        // Initialise volumes to 0
        this.playerA.volume = 0;
        this.playerB.volume = 0;
        this.setState(
            {
                activePlayer: this.playerB,
                waitingPlayer: this.playerA,
            },
            () => {
                if (init) {
                    this.changeTrack({ detail: init });
                }
            }
        );
        // Prevent keyboard keys on MAc etc. from manipulating audio
        if (navigator.mediaSession) {
            navigator.mediaSession.setActionHandler('play', () => null);
            navigator.mediaSession.setActionHandler('pause', () => null);
            navigator.mediaSession.setActionHandler('seekbackward', () => null);
            navigator.mediaSession.setActionHandler('seekforward', () => null);
        }
        this.listener = eventService
            .getEvent()
            .pipe(
                filter((event) =>
                    ['audio', 'soundEffect'].some((i) =>
                        event.type.split('/').includes(i)
                    )
                )
            )
            .subscribe((e) => {
                switch (e.type) {
                    case EVENTS.BG_AUDIO.FADE_OUT:
                        if (!mute) {
                            this.fade(true);
                        }
                        break;
                    case EVENTS.BG_AUDIO.FADE_IN:
                        if (!mute) {
                            this.fade(false);
                        }
                        break;
                    case EVENTS.AUDIO.PLAY:
                        this.playFX(e.detail);
                        break;
                    case EVENTS.SOUND_EFFECT_UNLOCK:
                        this.unlock(e.detail);
                        break;
                    case EVENTS.SOUND_EFFECT:
                        this.soundEffectStart(e);
                        break;
                    case EVENTS.SOUND_EFFECT_END:
                        this.soundEffectEnd(e);
                        break;
                    default:
                        break;
                }
            });
    }

    componentDidUpdate(prevProps) {
        const { mute: prevMute, init: prevInit } = prevProps;
        const { mute, init } = this.props;
        if (prevInit !== init) {
            this.changeTrack({ detail: init });
        }

        if (prevMute !== mute) {
            this.fade(mute);
        }
    }

    componentWillUnmount() {
        if (this.listener) {
            this.listener.unsubscribe();
        }
    }

    // Utility function to swap active player
    swapPlayers = (cb = null) => {
        this.setState(
            (prevState) => ({
                activePlayer: prevState.waitingPlayer,
                waitingPlayer: prevState.activePlayer,
            }),
            cb
        );
    };

    changeTrack = ({ detail: src }) => {
        const { activePlayer, waitingPlayer, faded } = this.state;
        const { mute } = this.props;

        if (!src) {
            return;
        }

        waitingPlayer.src = `${API_ENDPOINT}/${src}`;

        if (activePlayer.src === waitingPlayer.src) {
            return;
        }

        // Start waiting player then crossfade volumes, reset active player then swap
        if (isMobile() && !isAndroid()) {
            activePlayer.pause();
            activePlayer.currentTime = 0;
            waitingPlayer.play();
            this.swapPlayers();
            return;
        }

        waitingPlayer
            .play()
            .then(() => {
                if (mute) return [];
                return Promise.all([
                    adjustVolume(activePlayer, 0, {
                        duration: faded ? 0 : AUDIO_FADE_MS,
                    }),
                    adjustVolume(waitingPlayer, 1, { duration: AUDIO_FADE_MS }),
                ]);
            })
            .then(() => {
                activePlayer.volume = 0;
                activePlayer.pause();
                activePlayer.currentTime = 0;
                this.swapPlayers();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    fade = (out = true) => {
        const { activePlayer, waitingPlayer } = this.state;
        const { mute } = this.props;

        if (mute && !out) {
            return;
        }

        if (isMobile()) {
            out
                ? activePlayer.pause()
                : setTimeout(() => {
                      activePlayer.play();
                  }, 300);
            return;
        }

        const vol = out ? 0 : 1;

        adjustVolume(activePlayer, vol, {
            duration: out ? 0 : AUDIO_FADE_MS,
        }).catch((err) => {
            console.log(err);
        });

        this.setState({
            faded: out,
        });
    };

    playFX = (data) => {
        const { fx, unlocked } = this.state;
        const { mute } = this.props;

        if (mute || (isMobile() && !unlocked)) return;

        if (fx[data.name]) {
            fx[data.name].volume = 0.5;
            fx[data.name].play().catch(() => {});
        } else {
            console.warn(`Audio Manager: No effect named ${data.name}`);
        }
    };

    unlock = (data) => {
        const { fx, activePlayer, waitingPlayer, unlocked } = this.state;
        const { mute, init } = this.props;

        if (!isMobile() || isAndroid()) return;
        if (mute || unlocked) return;
        console.log('unlock');

        const startSound = init
            ? `${API_ENDPOINT}/${init}`
            : 'audio/chat-sound.mp3';

        activePlayer.src = startSound;
        waitingPlayer.src = startSound;
        activePlayer.volume = 0;
        waitingPlayer.volume = 0;
        Promise.all([activePlayer.play(), waitingPlayer.play()])
            .then(() => {
                if (!init) {
                    activePlayer.pause();
                    activePlayer.currentTime = 0;
                }
                waitingPlayer.pause();
                waitingPlayer.currentTime = 0;
                console.log('play mobile');
            })
            .catch((err) => {
                console.log(err);
            });

        if (fx[data.name]) {
            fx[data.name].volume = 0;
            fx[data.name].play().catch(() => {});
            fx[data.name].pause();
            fx[data.name].currentTime = 0;
        } else {
            console.warn(`Audio Manager: No effect named ${data.name}`);
        }
        this.setState({ unlocked: true });
    };

    soundEffectStart = () => {
        const { activePlayer } = this.state;

        adjustVolume(activePlayer, 0.5, { duration: AUDIO_FADE_MS });
    };

    soundEffectEnd = () => {
        const { activePlayer } = this.state;

        adjustVolume(activePlayer, 1, { duration: AUDIO_FADE_MS });
    };

    render() {
        return (
            <>
                <audio
                    ref={(ref) => {
                        this.playerA = ref;
                    }}
                    loop
                    preload='none'
                />
                <audio
                    ref={(ref) => {
                        this.playerB = ref;
                    }}
                    loop
                    preload='none'
                />
            </>
        );
    }
}
