import {
    all,
    call,
    cancelled,
    delay,
    fork,
    put,
    race,
    select,
    take,
    takeLatest,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import {
    CONTENT,
    EVENTS,
    INIT,
    MIN_TEXT_DUR_MS,
    NODE,
    STORAGE_KEY,
    SYSTEM_MSG_DELAY_MS,
} from '../../cfg/constants';
import { eventService } from '../../rxjs/event';
import { getReadSpeed } from '../../utils';
import request from '../../utils/request';
import {
    playerPause,
    setInit,
    setNodes,
    setPausedContent,
    showCurtain,
    showSpinner,
    setCurtainContent,
} from '../player/actions';
import {
    changeAudio,
    changeBg,
    loadBg,
    loadInteraction,
    loadNode,
    sessionError,
    setCfg,
    setContent,
    setDay,
    setHistory,
    setSeenMarkers,
    setSession,
    unitEnd,
    setPercent,
} from './actions';
import {
    checkContent,
    convertContent,
    convertOptions,
    generateUnitSummary,
    generateChapterStart,
    generateChapterEnd,
} from './ContentManager';

window.dataLayer = window.dataLayer || [];

const slugify = string => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};

const getTextDuration = (text, overrideMs = null, cfg) => {
    if (overrideMs !== '') {
        return overrideMs * 1000;
    }

    return Math.max(MIN_TEXT_DUR_MS, getReadSpeed(text, Number(cfg.readSpeed)));
};

export function* loadUnit(unit) {
    const data = yield call(request, `/api/s3/json/scripts/${unit}`);
    yield put(setNodes(data));
    console.log('loadUnit', unit);
}

export function* getSession() {
    let mongoId = localStorage.getItem(STORAGE_KEY);

    const requestURL = mongoId
        ? `/api/mongo/state/${mongoId}`
        : '/api/mongo/state';

    const requestOptions = mongoId
        ? {}
        : {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...INIT }),
        };

    try {
        // Call our request helper (see 'utils/request')
        const session = yield call(request, requestURL, requestOptions);
        yield put(setSession(session));
        yield call(loadUnit, session.initUnit);
        if (!mongoId) {
            // eslint-disable-next-line no-underscore-dangle
            localStorage.setItem(STORAGE_KEY, session._id);
            mongoId = session._id;
        }
        window.dataLayer.push({
            userId: mongoId,
        });
        Sentry.setUser({ id: mongoId });
    } catch (err) {
        yield put(sessionError(err));
    }
}

export function* updateSession() {
    const mongoId = localStorage.getItem(STORAGE_KEY);
    const requestURL = `/api/mongo/state/${mongoId}`;

    try {
        const session = yield select(state => state.session);
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(session),
        };

        yield call(request, requestURL, requestOptions);
    } catch (err) {
        yield put(sessionError(err));
    }
}

export function* loaded(content) {
    if (content.interactionType === NODE.STORY_END || checkContent(content)) {
        yield put(setContent(content));
    }
    yield put(loadInteraction(content));
}

export function* loadContent(action) {
    let createContent = [];

    try {
        const {
            nodes, voices, currentChapter, pausedContent,
        } = yield select(
            state => state.player,
        );
        const {
            initUnit,
            day,
            backgroundSet,
            cfg,
            backgroundAudio,
            seenMarkers,
            percent,
        } = yield select(state => state.session);

        const { interactive } = action;

        if (action.type === 'PLAYER_UNPAUSE') {
            createContent = pausedContent;
        } else {
            const node = nodes.find(n => n.id === action.payload);

            const copyNode = { ...node };

            if (!node) {
                return;
            }

            createContent = convertContent(
                copyNode,
                voices,
                initUnit,
                currentChapter,
            );
            createContent.push(
                convertOptions(
                    copyNode,
                    initUnit,
                    voices,
                    day,
                    interactive,
                    backgroundSet,
                    currentChapter,
                    backgroundAudio,
                    seenMarkers,
                    cfg,
                    percent,
                ),
            );
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const content of createContent) {
            let thisInterval = 50;
            const override = false; // need to look at this

            if (override) {
                thisInterval = 0;
            } else if (content.type === CONTENT.DIALOG) {
                yield put(showSpinner(true));
                thisInterval = getTextDuration(
                    content.text,
                    content.duration,
                    cfg,
                );
            }

            if (override) {
                yield call(loaded, content);
            } else {
                yield call(loaded, content);
                yield delay(thisInterval);
            }
        }
        yield put(showSpinner(false));
        yield put(setPausedContent([]));
    } finally {
        const { paused } = yield select(state => state.player);
        if (yield cancelled() && paused) {
            const [...savedHistory] = yield select(
                state => state.session.nodeHistory,
            );
            const lastNode = savedHistory.pop();
            const contentIdx = createContent.findIndex(
                c => c.historyId === lastNode.historyId,
            );
            const pausedContent = [
                ...createContent.slice(0, contentIdx),
                ...createContent.slice(contentIdx + 1),
            ];

            yield put(setPausedContent(pausedContent));
        }
    }
}

export function* loadNextUnit(action) {
    const content = generateUnitSummary(action.payload);
    yield call(loadUnit, action.payload.progress);
    const currentChapter = yield select(state => state.player.currentChapter);
    yield put(setContent(content));
    yield put(
        unitEnd({ unit: action.payload.progress, chapter: currentChapter }),
    );
    yield put(showCurtain(false));
}

export function* loadChapter(action) {
    const content = generateChapterStart(action.payload);
    yield put(setContent(content));
    yield put(loadNode(action.payload.progress));
    yield put(showCurtain(false));
    window.dataLayer.push({
        event: 'VirtualPageView',
        virtualPageURL: `/chapters/chapter${action.payload.chapter}`,
        virtualPageTitle: `Chapter ${action.payload.chapter}`,
    });
    Sentry.addBreadcrumb({
        category: 'chapter',
        message: `Chapter ${action.payload.chapter}`,
        level: Sentry.Severity.Info,
    });
}

export function* interaction(action) {
    // console.log('interaction', action);

    const { payload } = action;
    const init = yield select(state => state.player.init);
    // If there is no progress or choice app will be stuck.
    // This will rerun the last node so progress will resume
    if (!init && !payload.progress && payload.interactionType !== NODE.BRANCH) {
        const [...savedHistory] = yield select(
            state => state.session.nodeHistory,
        );

        const findLastNode = savedHistory
            .reverse()
            .find(
                h => h.interactionType === NODE.BRANCH
                    || h.interactionType === NODE.STORY_END
                    || h.progress
                    || h.unit === 'THE END',
            );

        if (findLastNode.interactionType === NODE.STORY_END) {
            yield put(setInit(true));
            eventService.sendEvent(EVENTS.SCROLL);
            return;
        }

        yield put(setHistory(findLastNode.historyId));
        yield put(setInit(true));
        yield put(loadNode(findLastNode.progress || findLastNode.choice));

        return;
    }
    if (!init) {
        yield put(setInit(true));
    }

    const markers = yield select(state => state.session.seenMarkers);

    switch (payload.interactionType) {
        case NODE.LINEAR:
            yield put(loadNode(payload.progress));
            break;
        case NODE.BG_AUDIO:
            const { bgAudio } = payload.options;
            yield put(loadNode(payload.progress));
            yield put(changeAudio(bgAudio));
            break;
        case NODE.SOUND_EFFECT:
            const { soundEffects } = payload.options;
            eventService.sendEvent(EVENTS.SOUND_EFFECT, soundEffects);
            yield put(loadNode(payload.progress));
            break;
        case NODE.AUDIO_EFFECT:
            const { track } = payload.options;
            eventService.sendEvent(EVENTS.SOUND_EFFECT, track);
            yield put(loadNode(payload.progress));
            break;
        case NODE.UNIT_END:
            const content = generateUnitSummary(payload);
            if (content.display) {
                const chapterEnd = generateChapterEnd(payload, content);
                yield put(setContent(chapterEnd));
            } else {
                yield call(loadUnit, payload.progress);
                const currentChapter = yield select(
                    state => state.player.currentChapter,
                );
                yield put(setContent(content));
                yield put(
                    unitEnd({
                        unit: payload.progress,
                        chapter: currentChapter,
                    }),
                );
            }
            window.dataLayer.push({
                event: 'VirtualPageView',
                virtualPageURL: `/chapters/chapter${payload.chapter}/${slugify(
                    payload.unit,
                )}`,
                virtualPageTitle: `Chapter ${payload.chapter} - ${payload.unit}`,
            });
            Sentry.addBreadcrumb({
                category: 'unit',
                message: `Chapter ${payload.chapter} - ${payload.unit}`,
                level: Sentry.Severity.Info,
            });
            break;
        case NODE.CHAPTER_START:
            const chapterStartContent = generateChapterStart(payload);
            yield put(
                setCurtainContent({
                    node: payload,
                    content: chapterStartContent,
                }),
            );
            break;
        case NODE.AUTO:
            const active = markers.indexOf(payload.options.auto.marker) > -1;
            const nextNode = active
                ? payload.options.auto.success
                : payload.options.auto.failure;
            yield put(loadNode(nextNode));
            break;
        case NODE.VIDEO:
        case NODE.IMAGE:
            if (
                payload.options
                && payload.options.image
                && payload.options.image.autoProgress
            ) {
                yield put(loadNode(payload.progress));
            }
            break;
        case NODE.DAY_CHANGE:
            yield put(setDay(payload.bookmark.day));
            yield put(loadNode(payload.progress));
            break;
        case NODE.PERCENT_CHANGE:
            yield put(setPercent(payload.bookmark.percent));
            yield put(loadNode(payload.progress));
            break;
        case NODE.MARKER:
            const updated = new Set(markers);
            updated.add(payload.options.marker);
            yield put(setSeenMarkers([...updated]));
            yield put(loadNode(payload.progress));
            break;
        case NODE.BG_LOAD:
            yield put(loadBg(payload));
            yield put(loadNode(payload.progress));
            break;
        case NODE.BG_CHANGE:
            yield put(changeBg(payload));
            yield put(loadNode(payload.progress));
            break;
        case NODE.SYSTEM:
            yield delay(SYSTEM_MSG_DELAY_MS);
            yield put(loadNode(payload.progress));
            break;
        case NODE.POSITION:
            yield put(setCfg({ chatPosition: payload.options.position }));
            yield put(loadNode(payload.progress));
            break;
        default:
            break;
    }
}

export function* rewind(action) {
    const { unit } = action.payload;
    yield call(updateSession, action);
    yield call(loadUnit, unit);
    yield put(setPausedContent([]));
    yield put(playerPause(false));
    // yield call(loaded, action);
}

export default function* sessionWatch() {
    yield all([
        fork(getSession),
        takeLatest(
            [
                'SET_DAY',
                'SET_CONTENT',
                'UNIT_END',
                'SET_CFG',
                'CHANGE_BG',
                'LOAD_BG',
                'CHANGE_AUDIO',
            ],
            updateSession,
        ),
        takeLatest(['LOAD_NODE', 'SET_CHOICE', 'PLAYER_UNPAUSE'], function* (
            ...args
        ) {
            yield race({
                task: call(loadContent, ...args),
                cancel: take('PLAYER_PAUSE'),
            });
        }),
        takeLatest(['REWIND_NODE'], rewind),
        takeLatest(['LOAD_INTERACTION'], interaction),
        takeLatest(['UNIT_CONTINUE'], loadNextUnit),
        takeLatest(['CHAPTER_START'], loadChapter),
    ]);
}
