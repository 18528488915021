import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import BitbPlayer from '../BitbPlayer';
import configureStore from '../../store/configureStore';

const initialState = {};
Sentry.init({
    dsn: 'https://74034286a18348c19ef4ed2dcbed2324@o237149.ingest.sentry.io/5443208',
    release: `thespace-boyinthebook-v2@${process.env.REACT_APP_VERSION}`,
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

export default class UnitController {
    constructor(container) {
        this.container = container;
        this.store = configureStore(initialState);
        document.addEventListener('contextmenu', event => {
            event.preventDefault();
        });
        document.addEventListener('dragstart', event => {
            event.preventDefault();
        });
    }

    /**
     * Initialise controller
     */
    async init() {
        this.loadUnit();
    }

    loadUnit = () => {
        ReactDOM.render(
            <Provider store={this.store}>
                <BitbPlayer />
            </Provider>,
            this.container,
        );
    };
}
