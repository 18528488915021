import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles/Card.scss';
import { connect } from 'react-redux';
import { setCurtainContent } from '../../store/player/actions';
import { eventService } from '../../rxjs/event';
import { EVENTS } from '../../cfg/constants';
/**
 * Text card component
 *
 * @author: Marcus Williams<`marcus.williams@joipolloi.com`>
 * @package: TheSpace-BITB
 */
class ChapterEndCard extends React.PureComponent {
    static propTypes = {
        isHead: PropTypes.bool.isRequired,
        sound: PropTypes.func.isRequired,
        node: PropTypes.object.isRequired,
        content: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.chapterEnd = this.chapterEnd.bind(this);
    }

    componentDidMount() {
        const { isHead, sound } = this.props;

        if (isHead) {
            sound();
            setTimeout(() => {
                eventService.sendEvent(EVENTS.SCROLL);
            }, 300);
        }
    }

    chapterEnd() {
        const { curtainContent, node, content } = this.props;
        curtainContent({ node, content });
    }

    /* eslint-disable react/no-danger */
    render() {
        const classes = classNames('Card', 'Card--required', 'Card--fullwidth');
        return (
            <article className={classes}>
                <button className='Card__ChapterEnd' onClick={this.chapterEnd}>
                    <span className='heading'>END OF CHAPTER</span>
                    <span className='subheading'>Select to continue</span>
                </button>
            </article>
        );
    }
    /* eslint-enable react/no-danger */
}

const mapDispatchToProps = (dispatch) => ({
    curtainContent: (payload) => {
        dispatch(setCurtainContent(payload));
    },
});
export default connect(null, mapDispatchToProps)(ChapterEndCard);
