/**
 * Sound Effects player - interacts with existing AudioController/index.js
 * to play sound effect type events over the top of the existing background audio
 *
 * @author: Matt Haynes <Matt.haynes@joipolloi.com>
 * @package: TheSpace-BITB
 * */

import React, { useRef, useEffect, useState } from 'react';
import { filter } from 'rxjs/operators';
import { EVENTS } from '../../cfg/constants';
import { eventService } from '../../rxjs/event';

const API_ENDPOINT = '/api/s3/sounds';

const SoundEffects = () => {
    const [audioToPlay, setAudioToPlay] = useState(null);
    const audioPlayer = useRef();

    useEffect(() => {
        const subscription = eventService
            .getEvent()
            .pipe(filter(event => event.type === EVENTS.SOUND_EFFECT))
            .subscribe(({ detail }) => {
                setAudioToPlay(detail);
            });
        if (audioPlayer.current) {
            audioPlayer.current.addEventListener('ended', () => eventService.sendEvent(EVENTS.SOUND_EFFECT_END, audioToPlay));
        }
        return () => {
            subscription.unsubscribe();
        };
    }, [audioToPlay, audioPlayer]);

    return audioToPlay ? (
        <audio
            id="audioPlayer"
            ref={audioPlayer}
            src={`${API_ENDPOINT}/${audioToPlay}`}
            autoPlay
        />
    ) : (
        <></>
    );
};

export default SoundEffects;
