import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Magnifier from 'react-magnifier';
import { useDispatch, useSelector } from 'react-redux';
import { CLOUD_URL, EVENTS, NODE } from '../../cfg/constants';
import { eventService } from '../../rxjs/event';
import { playerPause } from '../../store/player/actions';
import { loadNode } from '../../store/session/actions';
import { Video } from '../Interface';
import './styles/Lightbox.scss';

/**
 * Lightbox Component : output media
 * @author: Sam Anderson <`sam@joipolloi.com`>
 * @package: TheSpace-BITB
 */

const initialState = {
    currentMedia: '',
    loadedNode: null,
    open: false,
    src: '',
    progressOnComplete: false,
    imageArray: [],
    imageIndex: 0,
    bgChangeOnComplete: false,
    width: 0,
    height: 0,
    loaded: false,
};

export const Lightbox = (props) => {
    const node = useRef();
    const dispatch = useDispatch();
    const nodes = useSelector((state) => state.player.nodes);
    const [state, setState] = useState(initialState);
    const { detail, cfg } = props;

    const closeLightbox = useCallback(() => {
        const {
            progressOnComplete,
            currentMedia,
            progress,
            bgChangeOnComplete,
        } = state;
        dispatch(playerPause(false));
        if (progress && progressOnComplete) {
            dispatch(loadNode(progress));
        }

        if (!bgChangeOnComplete) {
            eventService.sendEvent(EVENTS.BG_AUDIO.FADE_IN);
        }

        if (currentMedia === 'video') {
            setState({
                ...state,
                open: false,
                src: '',
                currentMedia: '',
            });
        } else {
            setState(initialState);
        }
    }, [dispatch, state]);

    const handleClick = useCallback(
        (e) => {
            if (!node.current) {
                return;
            }
            if (node.current.contains(e.target)) {
                return;
            }
            closeLightbox();
        },
        [node, closeLightbox]
    );

    useEffect(() => {
        const { detail } = props;
        const { src, width, imageArray } = state;
        if (width !== 0 || (detail && detail.type !== 'image')) return;

        const img = new Image();
        let winWidth = window.innerWidth - 50;
        const winHeight = window.innerHeight - 200;

        if (Array.isArray(imageArray) && imageArray.length > 0) {
            winWidth = window.innerWidth - 170;
        }

        img.onload = function () {
            const ratio = this.width / this.height;
            let newHeight, newWidth;
            if (this.width > winWidth) {
                newWidth = winWidth;
                if (winWidth / ratio > winHeight) {
                    newHeight = winHeight;
                    newWidth = newHeight * ratio;
                } else {
                    newHeight = 'auto';
                }
            }

            if (this.height > winHeight && this.width < winWidth) {
                newHeight = winHeight;
                newWidth = winHeight * ratio;
            }

            setState({
                ...state,
                width: newWidth,
                height: newHeight,
            });
        };
        img.src = `${CLOUD_URL}/img/content/${src}`;
    }, [state]);

    useEffect(() => {
        // add when mounted
        document.addEventListener('mousedown', handleClick);

        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [node, state, handleClick]);

    useEffect(() => {
        if (detail) {
            const findNode = nodes.find((n) => n.id === detail.progress);

            setState({
                ...state,
                open: true,
                progressOnComplete: detail.options.progressOnComplete,
                imageArray: detail.imageArray,
                currentMedia: detail.type,
                loadedNode: detail.options.id,
                src: detail.src || 'placehold.png',
                progress: detail.progress,
                bgChangeOnComplete: !!(
                    findNode && findNode.type === NODE.BG_AUDIO
                ),
            });
            dispatch(playerPause(true));
        }
    }, [detail, dispatch]);

    if (!detail) {
        return null;
    }

    const load = (src, type = 'image', options = {}, progress, idx) => {
        setState({
            ...state,
            currentMedia: type,
            loadedNode: options.id,
            src,
            progress,
            imageIndex: idx,
        });
    };

    const cycleImage = (imageArray, index) => {
        const { imageIndex, progress } = state;
        const newIndex = imageIndex + index;
        load(
            imageArray[newIndex].src || 'placehold.png',
            imageArray[newIndex].type,
            imageArray[newIndex].options,
            progress,
            newIndex
        );
    };

    const {
        currentMedia,
        src,
        open,
        imageArray,
        imageIndex,
        progress,
        width,
        height,
    } = state;
    let nav;

    if (Array.isArray(imageArray) && imageArray.length > 0) {
        nav = (
            <div>
                <div className='Lightbox__Image__nav'>
                    {imageIndex > 0 ? (
                        <button
                            type='button'
                            className='Lightbox__Image__nav__button Lightbox__Image__nav__button--left'
                            onClick={() => cycleImage(imageArray, -1)}
                        >
                            &larr;
                        </button>
                    ) : null}
                    {imageIndex < imageArray.length - 1 ? (
                        <button
                            type='button'
                            className='Lightbox__Image__nav__button Lightbox__Image__nav__button--right'
                            onClick={() => cycleImage(imageArray, 1)}
                        >
                            &rarr;
                        </button>
                    ) : null}
                </div>
                <div className='Lightbox__Image__nav__counter'>
                    {imageIndex + 1} / {imageArray.length}
                </div>
            </div>
        );
    }
    const classes = classNames('Lightbox', {
        'Lightbox--active': open,
        'Lightbox--gallery': Array.isArray(imageArray) && imageArray.length > 0,
    });

    return (
        <div className={classes}>
            {currentMedia === 'image' && (
                <div className='Lightbox__Image' ref={node}>
                    <Magnifier
                        src={`${CLOUD_URL}/img/content/${src}`}
                        alt=''
                        mgShape='square'
                        mgWidth={375}
                        mgHeight={375}
                        zoomFactor={0.5}
                        mgTouchOffsetX={0}
                        mgTouchOffsetY={0}
                        width={width}
                        height={height}
                    />
                    <button
                        type='button'
                        className='Lightbox__Close'
                        onClick={closeLightbox}
                    >
                        Close
                    </button>

                    {nav}
                </div>
            )}
            {currentMedia === 'video' && (
                <div className='Lightbox__Container'>
                    <div className='Lightbox__Content' ref={node}>
                        <Video
                            src={src}
                            autoPlay={true}
                            subtitles={cfg.subtitles}
                            endHandler={closeLightbox}
                            progress={progress}
                            fluid
                        />
                        <button
                            type='button'
                            className='Lightbox__Close'
                            onClick={closeLightbox}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Lightbox;
