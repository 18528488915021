export const getDaysMessage = (days) =>
    `${days} day${days > 1 ? 's' : ''} passed`;

export const getPercentMessage = (percent) =>
    `${percent}% of chapter completed`;

/*
 * Calculate ms required to read a string at a set words per minute
 *-----------------------------------------------------------------------
 * This is currently very dumb and just goes off word count.
 * Opportunity to improve? ignore < 3 character words etc.
 */
export const getReadSpeed = (text, speed) => {
    const wordCount = text.split(' ').length;
    return Math.floor((wordCount / speed) * 60000);
};

export const debounce = (func, wait = 100) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    };
};

export const isMobile = () => {
    return navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        (!navigator.userAgent.match(/iPhone/i) &&
            navigator.maxTouchPoints > 1) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i)
        ? true
        : false;
};

export const isAndroid = () => {
    return navigator.userAgent.match(/Android/i) ? true : false;
};
