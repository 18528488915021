import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { CONTENT, EVENTS } from "../../cfg/constants";
import { ConfigProps } from "../../cfg/customProps";
import { eventService } from "../../rxjs/event";
import { showLightbox } from "../../store/player/actions";
import "./styles/Card.scss";

/**
 * Content Card component
 *
 * @author: Sam Anderson <`sam@joipolloi.com`>
 * @package: TheSpace-BITB
 */
class ContentCard extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        progress: PropTypes.string,
        autoOpen: PropTypes.bool,
        autoProgress: PropTypes.bool,
        thumbnail: PropTypes.string.isRequired,
        src: PropTypes.string,
        type: PropTypes.string,
        cfg: ConfigProps.isRequired,
        interactive: PropTypes.bool,
        imageArray: PropTypes.array,
        lightbox: PropTypes.func.isRequired,
    };

    static defaultProps = {
        autoOpen: false,
        autoProgress: false,
        src: "placehold.png",
        type: "image",
        interactive: false,
        progress: null,
        imageArray: [],
    };

    state = {
        active: false,
        loaded: false,
    };

    componentDidMount() {
        const { autoOpen, interactive } = this.props;
        if (autoOpen && interactive) {
            setTimeout(() => {
                this.clickHandler();
            }, 1000);
        }
        this.setState({
            active: !interactive,
        });
    }

    /**
     * Trigger the lightbox opening with content data and the id of the node to signal progress
     */
    clickHandler = () => {
        const {
            id,
            src,
            type,
            cfg,
            interactive,
            imageArray,
            progress,
            lightbox,
        } = this.props;

        const detail = {
            detail: {
                src,
                type,
                options: {
                    id,
                    subtitles: cfg.subtitles,
                    progressOnComplete: interactive,
                },
                imageArray,
                progress,
            },
        };

        this.setState(
            {
                active: true,
            },
            () => {
                lightbox(detail.detail);
            }
        );
    };

    handleImageLoaded() {
        setTimeout(() => this.setState({ loaded: true }), 400);
        eventService.sendEvent(EVENTS.SCROLL);
    }

    render() {
        const { active, loaded } = this.state;
        const { autoProgress, thumbnail, type, imageArray } = this.props;

        const wrapperClasses = classNames("Card__Wrapper", {
            "Card__Wrapper--loaded": loaded,
        });

        const classes = classNames(
            "Card Card--blank Card--fullwidth",
            { "Card--required": !autoProgress },
            { "Card--content-image": type === CONTENT.IMAGE },
            { "Card--image": type === CONTENT.IMAGE },
            { "Card--video": type === CONTENT.VIDEO },
            { activated: active }
        );
        if (type === CONTENT.VIDEO) {
            return (
                <div className={classes}>
                    <div className={wrapperClasses}>
                        <button type="button" onClick={this.clickHandler}>
                            <img
                                className="Card__Image"
                                src={thumbnail}
                                alt="Click the card to proceed"
                                onLoad={this.handleImageLoaded.bind(this)}
                            />
                            <div className="play-button">
                                <div className="carot" />
                            </div>
                        </button>
                    </div>
                </div>
            );
        }
        return (
            <div className={classes}>
                <div className={wrapperClasses}>
                    <button type="button" onClick={this.clickHandler}>
                        <img
                            className="Card__Image"
                            src={thumbnail}
                            alt="Click the card to proceed"
                            onLoad={this.handleImageLoaded.bind(this)}
                        />
                        {imageArray.length > 0 ? (
                            <div className="Card__Expand Card__Expand__Multi">
                                <span className="vh">Click to expand</span>
                            </div>
                        ) : (
                            <div className="Card__Expand">
                                <span className="vh">Click to expand</span>
                            </div>
                        )}
                    </button>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    lightbox: (payload) => {
        dispatch(showLightbox(payload));
    },
});
export default connect(null, mapDispatchToProps)(ContentCard);
