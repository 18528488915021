export const LOAD_SESSION = 'LOAD_SESSION';
export const SET_SESSION = 'SET_SESSION';
export const SESSION_ERROR = 'SESSION_ERROR';
export const SET_DAY = 'SET_DAY';
export const SET_PERCENT = 'SET_PERCENT';
export const LOAD_NODE = 'LOAD_NODE';
export const SET_CONTENT = 'SET_CONTENT';
export const SET_HISTORY = 'SET_HISTORY';
export const LOAD_INTERACTION = 'LOAD_INTERACTION';
export const UNIT_END = 'UNIT_END';
export const UNIT_CONTINUE = 'UNIT_CONTINUE';
export const CHAPTER_START = 'CHAPTER_START';
export const SET_CHOICE = 'SET_CHOICE';
export const LOAD_BG = 'LOAD_BG';
export const CHANGE_BG = 'CHANGE_BG';
export const CHANGE_AUDIO = 'CHANGE_AUDIO';
export const REWIND_NODE = 'REWIND_NODE';
export const SET_SEEN_MARKERS = 'SET_SEEN_MARKERS';
export const SET_CFG = 'SET_CFG';
