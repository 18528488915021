import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { EVENTS } from "../../cfg/constants";
import { BookmarkProps, ChoiceProps } from "../../cfg/customProps";
import { eventService } from "../../rxjs/event";
import { playerPause } from "../../store/player/actions";
import { selectPaused } from "../../store/player/selectors";
import { rewindNode, setChoice } from "../../store/session/actions";
import ChoiceButton from "./ChoiceButton";
import "./styles/Card.scss";

/**
 * Choice card component
 *
 * @author: Sam Anderson <`sam@joipolloi.com`>
 * @package: TheSpace-BITB
 */
class ChoiceCard extends React.PureComponent {
    static propTypes = {
        bookmark: BookmarkProps.isRequired,
        choices: PropTypes.arrayOf(ChoiceProps).isRequired,
        initChosen: PropTypes.bool,
        paused: PropTypes.bool.isRequired,
        isHead: PropTypes.bool,
        initChosenId: PropTypes.string,
        choice: PropTypes.func.isRequired,
        pausePlayer: PropTypes.func.isRequired,
        rewind: PropTypes.func.isRequired,
        historyId: PropTypes.string.isRequired,
    };

    static defaultProps = {
        isHead: false,
        initChosen: false,
        initChosenId: null,
    };

    constructor(props) {
        super(props);

        // If card initialises as chosen then deterine which to choose
        const { choices, initChosen, initChosenId } = this.props;
        const chosenId =
            initChosen &&
            choices?.indexOf(
                choices.find((c) => c.destination === initChosenId)
            );

        this.state = {
            chosen: initChosen,
            chosenId,
            leaving: false,
            activated: initChosen,
            confirmDisplay: false,
        };

        this.handleClick = this.handleClick.bind(this);
        this.choiceHandler = this.choiceHandler.bind(this);
        this.handleRewind = this.handleRewind.bind(this);
        this.cancel = this.cancel.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    componentDidMount() {
        this.handleLoaded();
    }

    componentDidUpdate(prevProps) {
        const { paused: prevPaused } = prevProps;
        const { paused, isHead } = this.props;
        if (prevPaused && !paused && isHead) {
            // this.handleLoaded();
        }
    }

    /**
     * Display message resulting from choice
     */
    handleClick = (chosenId) => {
        const { choices, historyId, choice } = this.props;
        this.setState(
            {
                chosen: true,
                leaving: false,
                activated: true,
            },
            () => {
                choice(historyId, choices[chosenId].destination);
            }
        );
    };

    choiceHandler = (chosenId = 0) => {
        this.setState(
            {
                leaving: true,
                chosenId,
            },
            () => setTimeout(() => this.handleClick(chosenId), 600)
        );
    };

    confirm = () => {
        const { pausePlayer } = this.props;
        this.setState(
            {
                confirmDisplay: true,
            },
            () => {
                pausePlayer(true);
                this.confirmRef.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        );
    };

    cancel = () => {
        const { pausePlayer } = this.props;
        this.setState({
            confirmDisplay: false,
        });
        pausePlayer(false);
    };

    handleRewind = () => {
        const { rewind, bookmark } = this.props;
        this.setState(
            {
                chosen: false,
                chosenId: false,
                leaving: false,
                activated: true,
                confirmDisplay: false,
            },
            () => rewind(bookmark)
        );
    };

    handleLoaded = () => {
        const { isHead, paused } = this.props;
        if (isHead && !paused) {
            setTimeout(() => {
                eventService.sendEvent(EVENTS.SCROLL, { duration: 1500 });
            }, 300);
        }
    };

    /**
     * Determine whether to render the options or the chosen message
     */
    renderComponent = () => {
        const { chosen, chosenId, leaving } = this.state;
        const { choices, historyId } = this.props;
        if (!chosen || leaving) {
            return choices.map((choice, idx) => (
                <ChoiceButton
                    key={`${historyId}-${choice.id}`}
                    idx={idx}
                    onClick={this.choiceHandler}
                    text={choice.text}
                    leaving={leaving}
                    chosen={chosenId === idx}
                    single={choices.length === 1}
                />
            ));
        }

        return (
            <ChoiceButton
                key={`${historyId}-${choices[chosenId].id}`}
                onClick={this.confirm}
                chosen
                text={choices && choices[chosenId] && choices[chosenId].text}
            />
        );
    };

    render() {
        const { activated, confirmDisplay } = this.state;
        const classes = classNames(
            "Card Card--choice Card--required Card--fullwidth",
            { activated }
        );

        return (
            <>
                <article className={classes}>{this.renderComponent()}</article>
                <div
                    className={confirmDisplay ? "" : "hidden"}
                    ref={(ref) => {
                        this.confirmRef = ref;
                    }}
                >
                    <div className="confirm-choice">
                        <p>Are you sure you want to rewind?</p>
                        <button type="button" onClick={this.cancel}>
                            Cancel
                        </button>
                        <button type="button" onClick={this.handleRewind}>
                            Confirm
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    paused: selectPaused(state),
});

const mapDispatchToProps = (dispatch) => ({
    choice: (historyId, payload) => {
        dispatch(setChoice(historyId, payload));
    },
    rewind: (payload) => {
        dispatch(rewindNode(payload));
    },
    pausePlayer: (payload) => {
        dispatch(playerPause(payload));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(ChoiceCard);
