import {
    SET_NODES,
    SHOW_LIGHTBOX,
    SHOW_SPINNER,
    SET_MARKERS,
    PLAYER_ERROR,
    PLAYER_PAUSE,
    SET_CURTAIN_CONTENT,
    SET_INIT,
    SHOW_CURTAIN,
    SET_PAUSED_CONTENT,
    PLAYER_UNPAUSE,
    SET_FULLSCREEN,
    SET_VIDEO_FULLSCREEN,
} from './constants';

export function setNodes(payload) {
    return {
        type: SET_NODES,
        payload,
    };
}

export function setMarkers(payload) {
    return {
        type: SET_MARKERS,
        payload,
    };
}

export function setInit(payload) {
    return {
        type: SET_INIT,
        payload,
    };
}

export function setFullscreen(payload) {
    return {
        type: SET_FULLSCREEN,
        payload,
    };
}

export function setVideoFullscreen(payload) {
    return {
        type: SET_VIDEO_FULLSCREEN,
        payload,
    };
}

export function showLightbox(payload) {
    return {
        type: SHOW_LIGHTBOX,
        payload,
    };
}

export function showSpinner(payload) {
    return {
        type: SHOW_SPINNER,
        payload,
    };
}

export function playerError(error) {
    return {
        type: PLAYER_ERROR,
        error,
    };
}

export function playerPause(payload) {
    return {
        type: payload ? PLAYER_PAUSE : PLAYER_UNPAUSE,
        payload,
    };
}

export function setPausedContent(payload) {
    return {
        type: SET_PAUSED_CONTENT,
        payload,
    };
}

export function setCurtainContent(payload) {
    return {
        type: SET_CURTAIN_CONTENT,
        payload,
    };
}

export function showCurtain(payload) {
    return {
        type: SHOW_CURTAIN,
        payload,
    };
}
