import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ChatBot } from '../ChatBot';
import ChapterContext from './ChapterContext';
import { selectCurrentChapter } from '../../store/player/selectors';

import { selectUnitsToLoad } from '../../store/session/selectors';

export const Chapter = (props) => {
    const { show, setShow } = useContext(ChapterContext);
    const { chapter } = props;
    const currentChapter = useSelector(selectCurrentChapter);
    const unitsToLoad = useSelector((state) =>
        selectUnitsToLoad(state, chapter)
    );

    useEffect(() => {
        setShow(chapter === currentChapter);
    }, [currentChapter, chapter, setShow]);

    const chapterId = `chapter${chapter}`;

    return (
        <div id={chapterId}>
            {unitsToLoad.map((unit) => (
                <ChatBot key={unit} unit={unit} show={show} />
            ))}
        </div>
    );
};

Chapter.propTypes = {
    chapter: PropTypes.string.isRequired,
};

export default Chapter;
