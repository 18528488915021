import shortid from 'shortid';
import { CONTENT, NODE } from '../../cfg/constants';
import { getDaysMessage, getPercentMessage } from '../../utils';

/**
 * Convert a source nodes content into renderable data
 * @param {array} node - Source node containing a content array
 * @param {array} voices - Array of source voice objects
 * @return {object} - Renderable content
 *-----------------------------------------------------------------------------
 * The source content is stored relative to its node. Rendered lines must exist
 * outside of this context. This converts the content array into individual
 * renderable nodes
 */
export const convertContent = (node, voices, unit, chapter) =>
    node.content.map((line) => {
        let voice = voices.find((v) => v.id === line.voice);
        if (!voice) {
            console.error(`Could not find voice for ${JSON.stringify(line)}`);
            voice = {
                defaultImg: '',
            };
        }
        voice.image = line.image ? line.image : voice.defaultImg;

        return {
            key: line.id,
            ...line,
            voice,
            type: CONTENT.DIALOG,
            historyId: shortid(),
            id: node.id,
            unit,
            chapter,
        };
    });

/**
 * Convert a source nodes options content into renderable data
 * @param {object} node - Source node containing options
 * @param {unit} unit - Name of current unit
 * @return {object} - Renderable options
 *-----------------------------------------------------------------------------
 * The source options are stored relative to the node. Rendered content must exist
 * outside of this context. This returns a type specific set of options
 * TODO: Image/Video content can probably be combined into single type as they only differ on auto
 */
export const convertOptions = (
    node,
    unit,
    voices,
    globalDay,
    interactive = true,
    backgroundSet,
    chapter,
    backgroundAudio,
    seenMarkers,
    cfg,
    globalPercent
) => {
    const historyId = shortid();
    const defaultContent = {
        key: `${node.id}-${historyId}`,
        id: node.id,
        historyId,
        unit,
        interactionType: node.type,
        bookmark: {
            unit,
            node: node.id,
            day: globalDay,
            percent: globalPercent,
            historyId,
            backgroundSet,
            backgroundAudio,
            seenMarkers,
            chatPosition: cfg.chatPosition,
        },
        chapter,
    };

    switch (node.type) {
        case NODE.BRANCH:
            return {
                ...defaultContent,
                choices: node.options.choices,
                type: CONTENT.USER_CHOICE,
                choice: node && node.meta && node.meta.next,
            };
        case NODE.IMAGE:
            return {
                ...defaultContent,
                media: node.options.image,
                type: CONTENT.IMAGE,
                progress: node.options.progress,
            };
        case NODE.VIDEO:
            return {
                ...defaultContent,
                media: node.options.video,
                type: CONTENT.VIDEO,
                progress: node.options.progress,
            };
        case NODE.INLINE_IMAGE: {
            const voice = voices.find(
                (v) => v.id === node.options.inlineImage.voice
            );
            return {
                ...defaultContent,
                media: {
                    ...node.options.inlineImage,
                    voice,
                },
                type: CONTENT.INLINE_IMAGE,
                progress: node.options.progress,
            };
        }
        case NODE.INLINE_VIDEO: {
            const voice = voices.find(
                (v) => v.id === node.options.inlineVideo.voice
            );
            return {
                ...defaultContent,
                media: {
                    ...node.options.inlineVideo,
                    voice,
                },
                type: CONTENT.INLINE_VIDEO,
                progress: node.options.progress,
            };
        }
        case NODE.DAY_CHANGE:
            return {
                ...defaultContent,
                bookmark: {
                    unit,
                    node: node.id,
                    day:
                        parseInt(globalDay, 10) +
                        parseInt(node.options.day, 10),
                    historyId,
                    backgroundSet,
                },
                type: CONTENT.SYSTEM_NOTIFICATION,
                progress: node.options.progress,
                message: getDaysMessage(node.options.day),
                classes: 'white calendar',
            };
        case NODE.PERCENT_CHANGE:
            return {
                ...defaultContent,
                bookmark: {
                    unit,
                    node: node.id,
                    percent: node.options.percent,
                    historyId,
                    backgroundSet,
                },
                type: CONTENT.PERCENT_CHANGE,
                progress: node.options.progress,
                message: getPercentMessage(node.options.percent),
                classes: 'white percent',
            };
        case NODE.SYSTEM:
            return {
                ...defaultContent,
                progress: node.options.progress,
                type: CONTENT.SYSTEM_NOTIFICATION,
                message: node.options.system,
            };
        default:
            return {
                ...defaultContent,
                options: node.options,
                progress: node.options.progress,
            };
    }
};

export const generateUnitSummary = (node) => ({
    key: node.key,
    id: node.id,
    historyId: node.historyId,
    unit: node.unit,
    chapter: node.chapter,
    type: CONTENT.UNIT_SUMMARY,
    ...node.options.unitSummary,
});

export const generateChapterStart = (node) => ({
    key: node.key,
    id: node.id,
    historyId: node.historyId,
    unit: node.unit,
    chapter: node.chapter,
    type: CONTENT.CHAPTER_START,
    ...node.options.unitSummary,
});

export const generateChapterEnd = (node, content) => ({
    key: node.key,
    id: node.id,
    historyId: shortid(),
    unit: node.unit,
    chapter: node.chapter,
    type: CONTENT.CHAPTER_END,
    node,
    content,
});

export const checkContent = ({ type }) => {
    return Object.keys(CONTENT).find((key) => CONTENT[key] === type);
};
