import React, { useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import './styles/Card.scss';
import { CLOUD_URL, EVENTS } from '../../cfg/constants';
import ChapterContext from '../BitbPlayer/ChapterContext';
import { eventService } from '../../rxjs/event';
/**
 * Unit Summary card component
 *
 * @author: Sam Anderson <`sam@joipolloi.com`>
 * @package: TheSpace-BITB
 */
const SummaryCard = ({
    clickHandler,
    image,
    text,
    title,
    display,
    card,
    button_text,
    instruction,
    toggle,
    chapter,
}) => {
    const [double, setDouble] = useState(false);
    const { show, setShow } = useContext(ChapterContext);

    const scrollIntoView = (node) => {
        const delay = show ? 1500 : 1000;
        setTimeout(() => {
            if (show) {
                const chapterElement = document.getElementById(
                    `chapter${chapter}`
                );
                chapterElement.scrollIntoView({ block: 'start' });
            } else {
                node.scrollIntoView({ block: 'end' });
            }
        }, delay);
    };
    const summaryRef = useCallback(
        (node) => {
            if (node) {
                scrollIntoView(node);
            } else {
                eventService.sendEvent(EVENTS.SCROLL);
            }
        },
        [show]
    );

    if (!display) {
        return null;
    }

    return (
        <div className='ChatBot__Summary' ref={summaryRef}>
            <div className='Card Card--summary Card--fullwidth Summary'>
                {display && (
                    <>
                        <div className='Summary__Image'>
                            <img
                                src={`${CLOUD_URL}/img/unit_summary/${image}`}
                                alt=''
                            />
                        </div>
                        <div className='Summary__Text'>
                            <p className='Summary__Text--title bold'>
                                <span className='vh'>
                                    Click here to reload{' '}
                                </span>
                                {title}
                            </p>
                            <div className='Summary__Scroll'>
                                <p
                                    className='Summary__Text--body'
                                    dangerouslySetInnerHTML={{
                                        __html: formatHtmlTags(text),
                                    }}
                                />
                            </div>
                            {instruction && (
                                <span
                                    className='Summary__Text--instruction'
                                    dangerouslySetInnerHTML={{
                                        __html: formatHtmlTags(
                                            `${instruction}<br>This story will auto-save. You are free to come back to it at any time.`
                                        ),
                                    }}
                                />
                            )}
                            {card && toggle && (
                                <button
                                    type='button'
                                    onClick={() => setShow(!show)}
                                    className='Card--button'
                                >
                                    {show ? 'Hide' : 'Show'}
                                </button>
                            )}
                        </div>
                    </>
                )}
            </div>
            {!card && (
                <button
                    type='button'
                    disabled={double}
                    onClick={() => {
                        clickHandler();
                        setDouble(true);
                    }}
                    className='Card--button'
                >
                    {button_text}
                </button>
            )}
        </div>
    );
};

/**
 *
 * @param {string} text
 *
 * Format any html entity tags within a string
 *
 */
const formatHtmlTags = (text) => {
    for (let i = 0; i < text.length; i++) {
        const openTag = '[';
        const closeTag = ']';
        const tag = text.charAt(i + 1);
        switch (text.charAt(i)) {
            case openTag:
                let classname = '';
                switch (tag) {
                    case 'b':
                        classname = 'bold';
                        break;
                    case 'i':
                        classname = 'italic';
                        break;
                    case 'u':
                        classname = 'underline';
                        break;
                    default:
                        break;
                }
                // check for closing tag to ensure we are dealing with html tag
                // and not actual string text
                if (text.charAt(i + 2) === closeTag) {
                    if (classname.length > 0) {
                        text = text
                            .split(openTag + tag + closeTag)
                            .join(`<${tag} class="${classname}">`);
                        text = text
                            .split(`${openTag}/${tag}${closeTag}`)
                            .join(`</${tag}>`);
                    }
                }
                break;
            default:
                break;
        }
    }
    return text;
};

SummaryCard.propTypes = {
    clickHandler: PropTypes.func,
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    instruction: PropTypes.string,
    display: PropTypes.bool,
    card: PropTypes.bool,
    button_text: PropTypes.string,
    toggle: PropTypes.bool,
};

SummaryCard.defaultProps = {
    title: 'Placeholder',
    text: 'No summary text supplied',
    image: 'placehold.png',
    instruction: null,
    display: false,
    card: false,
    clickHandler: () => null,
    button_text: 'Continue',
    toggle: true,
};
export default SummaryCard;
