import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { customMarkup } from './utils';
import { VoiceProps } from '../../cfg/customProps';
import { EVENTS, CLOUD_URL } from '../../cfg/constants';
import './styles/Card.scss';
import { eventService } from '../../rxjs/event';

/**
 * Text card component
 *
 * @author: Sam Anderson <`sam@joipolloi.com`>
 * @package: TheSpace-BITB
 */
export default class TextCard extends React.PureComponent {
    static propTypes = {
        text: PropTypes.string.isRequired,
        voice: VoiceProps,
        isHead: PropTypes.bool.isRequired,
        sound: PropTypes.func.isRequired,
    };

    static defaultProps = {
        voice: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        };
    }


    handleImageLoaded() {
        const { isHead } = this.props;
        this.setState({
            loaded: true,
        });
        if (isHead) {
            eventService.sendEvent(EVENTS.SCROLL);
        }
    }

    handleSound() {
        const { isHead, sound } = this.props;

        if (isHead) {
            sound();
        }
    }

    /* eslint-disable react/no-danger */
    render() {
        const {
            text, voice,
        } = this.props;
        const { loaded } = this.state;
        const classes = classNames('Card__Wrapper', {
            'Card__Wrapper--loaded': loaded,
        });
        return (
            <article className="Card" onLoad={this.handleSound.bind(this)}>
                <div className={classes}>
                    <img
                        className="Card__Avatar"
                        src={`${CLOUD_URL}/img/avatar/${voice.image}`}
                        alt={voice.name}
                        onLoad={this.handleImageLoaded.bind(this)}
                    />
                    <div className="Card__Content">
                        <h1 className="vh">{voice.name}</h1>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: customMarkup(text),
                            }}
                        />
                    </div>
                </div>
            </article>
        );
    }
    /* eslint-enable react/no-danger */
}
