import React from 'react';
import { useSelector } from 'react-redux';
import Counter from './Counter';
import './styles/Days.scss';
import { selectDay } from '../../store/session/selectors';
/**
 * UI component to display current 'Day' of experience
 * @author: Sam Anderson <`sam@joipolloi.com`>
 * @package: TheSpace-BITB
 */

const Days = () => {
    const day = useSelector(selectDay);
    return (
        <div className="Days">
            <div className="Days__Icon" key={day}>
                <span className="Days__Text">Day</span>
                <Counter
                    className="Days__Text Days__Text--large"
                    number={day}
                />
            </div>
        </div>
    );
};

export default React.memo(Days);
