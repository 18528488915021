import { INIT } from '../../cfg/constants';
import {
    CHANGE_AUDIO,
    CHANGE_BG,
    LOAD_BG,
    REWIND_NODE,
    SET_CFG,
    SET_CHOICE,
    SET_CONTENT,
    SET_DAY,
    SET_HISTORY,
    SET_SEEN_MARKERS,
    SET_SESSION,
    UNIT_END,
    SET_PERCENT,
} from './constants';
// The initial state of the App
export const initialState = INIT;

const chapters = (history) =>
    history.map((n) => n.chapter).filter((v, i, a) => a.indexOf(v) === i);

function addContent(state, action) {
    const check = [...state.nodeHistory].some(
        ({ historyId }) => historyId === action.payload.historyId
    );
    if (!check) {
        const newHistory = [...state.nodeHistory, action.payload];
        return { ...state, nodeHistory: newHistory };
    }
    return { ...state };
}

function setHistory(state, action) {
    const idx = state.nodeHistory.findIndex(
        (h) => h.historyId === action.payload
    );
    const newHistory = state.nodeHistory.slice(0, idx + 1);
    return {
        ...state,
        nodeHistory: newHistory,
    };
}

function changeBg(state, action) {
    const findLayer = state.backgroundSet.layers.find(
        (l) => l.layer === action.payload.options.bgLayer.layer
    );
    if (findLayer) {
        return {
            ...state,
            backgroundSet: {
                layerSet: state.backgroundSet.layerSet,
                layers: state.backgroundSet.layers.map((l) => {
                    if (l.layer !== action.payload.options.bgLayer.layer) {
                        return l;
                    }
                    return action.payload.options.bgLayer;
                }),
            },
        };
    }
    return {
        ...state,
        backgroundSet: {
            layerSet: state.backgroundSet.layerSet,
            layers: [
                ...state.backgroundSet.layers,
                action.payload.options.bgLayer,
            ],
        },
    };
}

function rewindNode(state, action) {
    const unitIdx = state.unitsToLoad.findIndex(
        (u) => u.unit === action.payload.unit
    );
    const newUnitsToLoad = state.unitsToLoad.slice(0, unitIdx + 1);
    const nodeIdx = state.nodeHistory.findIndex(
        (n) => n.historyId === action.payload.historyId
    );
    const newHistory = state.nodeHistory
        .slice(0, nodeIdx + 1)
        .map((h) =>
            h.historyId !== action.payload.historyId
                ? h
                : { ...h, choice: null }
        );

    const cfg = { ...state.cfg, chatPosition: action.payload.chatPosition };
    return {
        ...state,
        day: action.payload.day,
        percent: action.payload.percent,
        initUnit: action.payload.unit,
        backgroundSet: action.payload.backgroundSet,
        backgroundAudio: action.payload.backgroundAudio,
        unitsToLoad: newUnitsToLoad,
        nodeHistory: newHistory,
        chapters: chapters(newUnitsToLoad),
        seenMarkers: action.payload.seenMarkers,
        cfg: cfg,
    };
}

function unitEnd(state, action) {
    const units = [...state.unitsToLoad, action.payload];
    return {
        ...state,
        initUnit: action.payload.unit,
        unitsToLoad: units,
        chapters: chapters(units),
    };
}

/* eslint-disable default-case, no-param-reassign */
const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SESSION:
            return {
                ...state,
                ...action.payload,
            };
        case SET_CFG:
            return {
                ...state,
                cfg: { ...state.cfg, ...action.payload },
            };
        case SET_CONTENT:
            return addContent(state, action);
        case SET_HISTORY:
            return setHistory(state, action);
        case SET_DAY:
            return {
                ...state,
                day: action.payload.day,
            };
        case SET_PERCENT:
            return {
                ...state,
                percent: action.payload.percent,
            };
        case SET_SEEN_MARKERS:
            return {
                ...state,
                seenMarkers: action.payload,
            };
        case SET_CHOICE:
            return {
                ...state,
                nodeHistory: state.nodeHistory.map((h) =>
                    h.historyId !== action.historyId
                        ? h
                        : { ...h, choice: action.payload }
                ),
            };
        case UNIT_END:
            return unitEnd(state, action);
        case LOAD_BG:
            if (action.payload.options.bgLayer.layer === '') {
                action.payload.options.bgLayer.layer = '0';
            }
            return {
                ...state,
                backgroundSet: {
                    layerSet: action.payload.options.bgSet,
                    layers: [action.payload.options.bgLayer],
                },
            };
        case CHANGE_BG:
            return changeBg(state, action);
        case CHANGE_AUDIO:
            return {
                ...state,
                backgroundAudio: action.payload,
            };
        case REWIND_NODE:
            return rewindNode(state, action);
        default:
            return state;
    }
};

export default sessionReducer;
