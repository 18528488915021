import {
    all, call, put, fork,
} from 'redux-saga/effects';
import request from '../../utils/request';
import { playerError, setMarkers } from './actions';

export function* getMarkers() {
    try {
    // Call our request helper (see 'utils/request')
        const markers = yield call(request, '/api/s3/json/markers/index');
        yield put(setMarkers(markers.content));
    } catch (err) {
        yield put(playerError(err));
    }
}

export default function* playerWatch() {
    yield all([fork(getMarkers)]);
}
