/**
* BITB specific utilities and other useful functions
*
* @author Sam Anderson <sam.anderson@joipolloi.com>
* @package BITB
*/

/**
 * Strip the extension from a filename
 * @param {string} src
 * @param {string} newExt
 */
export const stripExtension = (src, newExt = '') => {
    const lastDotIndex = src.lastIndexOf('.');
    return `${src.substr(0, lastDotIndex)}${newExt}`;
};

/**
 * Generate a DOM element and apply an initial class
 * @param {String} className - Classname to apply to the DOM element
 * @param {String} elType - Type of DOM element to create
 * @param attr
 */
export const createDOM = (className, elType = 'div', attr = {}) => {
    const dom = document.createElement(elType);
    if (className !== '') {
        dom.classList.add(className);
    }
    Object.keys(attr).forEach(key => {
        dom.setAttribute(key, attr[key]);
    });
    return dom;
};

/**
 * Convert a JSON object into storable string
 * Prevents recursive warnings
 * @param {object} obj - Object to convert
 * @returns {string} - Converted object
 */
export const stringifyJSON = obj => {
    const seen = [];
    const parsed = JSON.stringify(obj, (key, val) => {
        if (val != null && typeof val === 'object') {
            if (seen.indexOf(val) >= 0) {
                return;
            }
            seen.push(val);
        }
        /* eslint-disable consistent-return */
        return val;
        /* eslint-enable consistent-return */
    });

    return parsed;
};
