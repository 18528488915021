import {
    LOAD_SESSION,
    SET_SESSION,
    SESSION_ERROR,
    SET_DAY,
    LOAD_NODE,
    SET_CONTENT,
    LOAD_INTERACTION,
    UNIT_END,
    SET_CHOICE,
    LOAD_BG,
    REWIND_NODE,
    SET_SEEN_MARKERS,
    CHANGE_BG,
    CHANGE_AUDIO,
    SET_CFG,
    UNIT_CONTINUE,
    SET_HISTORY,
    CHAPTER_START,
    SET_PERCENT,
} from './constants';

export function loadSession() {
    return {
        type: LOAD_SESSION,
    };
}

export function setSession(payload) {
    return {
        type: SET_SESSION,
        payload,
    };
}

export function sessionError(error) {
    return {
        type: SESSION_ERROR,
        error,
    };
}

export function setDay(day) {
    return {
        type: SET_DAY,
        payload: { day },
    };
}

export function setPercent(percent) {
    return {
        type: SET_PERCENT,
        payload: { percent },
    };
}

export function loadNode(payload, interactive) {
    return {
        type: LOAD_NODE,
        payload,
        interactive,
    };
}

export function rewindNode(payload) {
    return {
        type: REWIND_NODE,
        payload,
    };
}

export function setContent(payload) {
    return {
        type: SET_CONTENT,
        payload,
    };
}

export function setHistory(payload) {
    return {
        type: SET_HISTORY,
        payload,
    };
}

export function setChoice(historyId, payload) {
    return {
        type: SET_CHOICE,
        historyId,
        payload,
    };
}
export function setSeenMarkers(payload) {
    return {
        type: SET_SEEN_MARKERS,
        payload,
    };
}

export function loadInteraction(payload) {
    return {
        type: LOAD_INTERACTION,
        payload,
    };
}

export function unitEnd(payload) {
    return {
        type: UNIT_END,
        payload,
    };
}

export function unitContinue(payload) {
    return {
        type: UNIT_CONTINUE,
        payload,
    };
}

export function startChapter(payload) {
    return {
        type: CHAPTER_START,
        payload,
    };
}

export function loadBg(payload) {
    return {
        type: LOAD_BG,
        payload,
    };
}

export function changeBg(payload) {
    return {
        type: CHANGE_BG,
        payload,
    };
}

export function changeAudio(payload) {
    return {
        type: CHANGE_AUDIO,
        payload,
    };
}

export function setCfg(payload) {
    return {
        type: SET_CFG,
        payload,
    };
}
