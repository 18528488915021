import {
    SET_NODES,
    SHOW_LIGHTBOX,
    SHOW_SPINNER,
    SET_MARKERS,
    PLAYER_PAUSE,
    SET_CURTAIN_CONTENT,
    SET_INIT,
    SHOW_CURTAIN,
    SET_PAUSED_CONTENT,
    PLAYER_UNPAUSE,
    SET_FULLSCREEN,
    SET_VIDEO_FULLSCREEN,
} from './constants';

export const initialState = {
    currentChapter: null,
    curtainActive: true,
    curtainContents: null,
    menuActive: false,
    paused: false,
    pausedContent: [],
    startNode: null,
    nodes: [],
    voices: [],
    markers: [],
    lightbox: null,
    spinner: false,
    init: false,
    fullscreen: true,
    videoFullscreen: false,
};

/* eslint-disable default-case, no-param-reassign */
const playerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NODES:
            return {
                ...state,
                startNode: action.payload.startNode,
                nodes: action.payload.nodes,
                voices: action.payload.voices,
                currentChapter: action.payload.chapter,
            };
        case SET_INIT:
            return {
                ...state,
                init: action.payload,
            };
        case SET_FULLSCREEN:
            return {
                ...state,
                fullscreen: action.payload,
            };
        case SET_VIDEO_FULLSCREEN:
            return {
                ...state,
                videoFullscreen: action.payload,
            };
        case SET_MARKERS:
            return {
                ...state,
                markers: action.payload,
            };
        case SET_CURTAIN_CONTENT:
            return {
                ...state,
                curtainContents: action.payload,
                curtainActive: !!action.payload,
            };
        case SHOW_CURTAIN:
            return {
                ...state,
                curtainActive: action.payload,
            };
        case SHOW_LIGHTBOX:
            return {
                ...state,
                lightbox: action.payload,
            };
        case SHOW_SPINNER:
            return {
                ...state,
                spinner: action.payload,
            };
        case PLAYER_PAUSE:
        case PLAYER_UNPAUSE:
            return {
                ...state,
                paused: action.payload,
            };
        case SET_PAUSED_CONTENT:
            return {
                ...state,
                pausedContent: action.payload,
            };
        default:
            return state;
    }
};

export default playerReducer;
