import { createSelector } from 'reselect';

const getPaused = (state) => state.player.paused;

export const selectPaused = createSelector(getPaused, (paused) => paused);

const getSpinner = (state) => state.player.spinner;

export const selectSpinner = createSelector(getSpinner, (spinner) => spinner);

const getCurrentChapter = (state) => state.player.currentChapter;

export const selectCurrentChapter = createSelector(
    getCurrentChapter,
    (currentChapter) => currentChapter
);

const getLightbox = (state) => state.player.lightbox;

export const selectLightbox = createSelector(
    getLightbox,
    (lightbox) => lightbox
);

const getCurtainActive = (state) => state.player.curtainActive;

export const selectCurtainActive = createSelector(
    getCurtainActive,
    (curtainActive) => curtainActive
);

const getFullscreen = (state) => state.player.fullscreen;

export const selectFullscreen = createSelector(
    getFullscreen,
    (fullscreen) => fullscreen
);

const getVideoFullscreen = (state) => state.player.videoFullscreen;

export const selectVideoFullscreen = createSelector(
    getVideoFullscreen,
    (videoFullscreen) => videoFullscreen
);

const getCurtainContents = (state) => state.player.curtainContents;

export const selectCurtainContents = createSelector(
    getCurtainContents,
    (curtainContents) => curtainContents
);

const getStartNode = (state) => state.player.startNode;

export const selectStartNode = createSelector(
    getStartNode,
    (startNode) => startNode
);
