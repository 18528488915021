import { createSelector } from 'reselect';

const getInitUnit = (state) => state.session.initUnit;

export const selectInitUnit = createSelector(
    getInitUnit,
    (initUnit) => initUnit
);

const getDay = (state) => state.session.day;

export const selectDay = createSelector(getDay, (day) => day);

const getPercent = (state) => state.session.percent;

export const selectPercent = createSelector(getPercent, (percent) => percent);

const getChapters = (state) => state.session.chapters;

export const selectChapters = createSelector(
    getChapters,
    (chapters) => chapters
);

const getBackgroundAudio = (state) => state.session.backgroundAudio;

export const selectBackgroundAudio = createSelector(
    getBackgroundAudio,
    (backgroundAudio) => backgroundAudio
);

const getLayerSet = (state) => state.session.backgroundSet.layerSet;

export const selectLayerSet = createSelector(
    getLayerSet,
    (layerSet) => layerSet
);

const getLayers = (state) => state.session.backgroundSet.layers;

export const selectLayers = createSelector(getLayers, (layers) =>
    layers.sort((a, b) => a.layer - b.layer)
);

const getCfg = (state) => state.session.cfg;

export const selectCfg = createSelector(getCfg, (cfg) => cfg);

const getUnitsToLoad = (state) => state.session.unitsToLoad;
const getChapter = (state, chapter) => chapter;

export const selectUnitsToLoad = createSelector(
    [getUnitsToLoad, getChapter],
    (units, chapter) =>
        units.filter((h) => h.chapter === chapter).map((h) => h.unit)
);

const getNodeHistory = (state) => state.session.nodeHistory;
const getUnit = (state, unit) => unit;

export const selectNodeHistory = createSelector(
    [getUnit, getNodeHistory],
    (unit, history) => history.filter((h) => h.unit === unit)
);
